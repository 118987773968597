import { createSlice } from '@reduxjs/toolkit'
import { Company } from '../../../models/Company'
import authActions from '../auth/actions'
import actions from './actions'

export interface CompanyState {
  company?: Company
  error?: string
  currentRequest?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
  subscriptionAwaitingSync: boolean
  paymentAwaitingSync: boolean
}

const initialState: CompanyState = {
  company: undefined,
  status: 'initial',
  subscriptionAwaitingSync: false,
  paymentAwaitingSync: false,
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.updateCompany, (state, action) => {
        state.company = action.payload
        if (state.company.stripePaymentMethodId) {
          state.subscriptionAwaitingSync = false
        }
        if (
          state.company.stripePaymentMethodId &&
          state.company.creditsPurchased === state.company.monthlyCreditLimit
        ) {
          state.paymentAwaitingSync = false
        }
      })
      .addCase(actions.cancelSubscription, state => {
        if (state.company) {
          state.company.stripePaymentMethodId = null
        }
        state.subscriptionAwaitingSync = false
        state.paymentAwaitingSync = false
      })
      .addCase(actions.topup, state => {
        state.paymentAwaitingSync = true
      })
      .addCase(actions.purchaseSubscription, state => {
        state.subscriptionAwaitingSync = true
        if (
          state.company?.monthlyCreditLimit &&
          state.company.monthlyCreditLimit > state.company.creditsPurchased
        ) {
          state.paymentAwaitingSync = true
        }
      })
      .addCase(actions.loadCompany.pending, (state, action) => {
        if (action.meta.arg.companyId !== state.company?.id) {
          state.company = undefined
        }
        state.currentRequest = action.meta.requestId
        state.status = 'loading'
      })
      .addCase(actions.loadCompany.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.company = action.payload.data
          state.error = undefined
          if (state.company.stripePaymentMethodId) {
            state.subscriptionAwaitingSync = false
          }
          if (
            state.company.stripePaymentMethodId &&
            state.company.creditsPurchased === state.company.monthlyCreditLimit
          ) {
            state.paymentAwaitingSync = false
          }
        } else {
          action.payload.unsubscribe()
        }
      })
      .addCase(actions.loadCompany.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
  },
})

export default companySlice
