import { RootState } from '../../types/RootState'

class TemplateSelectors {
  getTemplate = (state: RootState) => state.template.template
  getLoading = (state: RootState) =>
    state.template.status === 'initial' || state.template.status === 'loading'
  getHasError = (state: RootState) => state.template.status === 'failed'
  getError = (state: RootState) => state.template.error
}

export default new TemplateSelectors()
