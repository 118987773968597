if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = function () {
    let resolve, reject
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { promise, resolve, reject }
  } as typeof Promise.withResolvers
}
