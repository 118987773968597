import { useEffect, useState } from 'react'
import useStableCallback from './useStableCallback'

export const useQuery = <T>(fn: () => Promise<T>) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<T>()
  const stableFn = useStableCallback(fn)
  useEffect(() => {
    setLoading(true)
    stableFn()
      .then(data => {
        setLoading(false)
        setData(data)
      })
      .catch((error: Error) => {
        setLoading(false)
        setError(error.message)
      })
  }, [stableFn])
  return { loading, error, data }
}
