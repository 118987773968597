import { FC, Suspense, lazy, memo } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SuspensePanel from './components/atoms/SuspensePanel'
import PrivateRoute from './components/routeSecurity/PrivateRoute'
import PublicRoute from './components/routeSecurity/PublicRoute'
import AwaitEmailVerificationRoute from './components/routeSecurity/AwaitEmailVerificationRoute'
import PageLayout from './components/routeLayouts/PageLayout'
import { useSubscribeToCompany } from './hooks/company/useSubscribeToCompany'
import { useSubscribeToAuthState } from './hooks/auth/useSubscribeToAuthState'
import { useRefreshAuthTokenOnClaimsChange } from './hooks/auth/useRefreshAuthTokenOnClaimsChange'
import SuperAdminSwitchRoute from './components/routeSecurity/SuperAdminSwitchRoute'

const LoginPage = lazy(() => import('./components/pages/LoginPage'))
const TokenLoginPage = lazy(() => import('./components/pages/TokenLoginPage'))
const RegisterPage = lazy(() => import('./components/pages/RegisterPage'))
const DashboardPage = lazy(() => import('./components/pages/DashboardPage'))
const AdminDashboardPage = lazy(() => import('./components/pages/AdminDashboardPage'))
const DeadLetterEventsPage = lazy(() => import('./components/pages/DeadLetterEventsPage'))
const VerifyPage = lazy(() => import('./components/pages/VerifyPage'))
const WelcomePage = lazy(() => import('./components/pages/WelcomePage'))
const ResetPasswordSuccessPage = lazy(() => import('./components/pages/ResetPasswordSuccessPage'))
const RequestPasswordResetPage = lazy(() => import('./components/pages/RequestPasswordResetPage'))
const AwaitEmailVerificationPage = lazy(
  () => import('./components/pages/AwaitEmailVerificationPage'),
)
const NoMatchPage = lazy(() => import('./components/pages/NoMatchPage'))

const App: FC = () => {
  useSubscribeToAuthState()
  useRefreshAuthTokenOnClaimsChange()
  useSubscribeToCompany()
  return (
    <Router>
      <Suspense fallback={<SuspensePanel />}>
        <Routes>
          <Route element={<PageLayout />}>
            <Route
              path="/"
              element={
                <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                  <SuperAdminSwitchRoute
                    superAdminPage={<AdminDashboardPage />}
                    regularPage={<DashboardPage />}
                  />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/subscription"
              element={
                <PrivateRoute allowedRoles={['owner']}>
                  <DashboardPage />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/verify"
              element={
                <PublicRoute>
                  <VerifyPage />
                </PublicRoute>
              }
            />
            <Route
              path="/welcome"
              element={
                <PublicRoute>
                  <WelcomePage />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password-success"
              element={
                <PublicRoute>
                  <ResetPasswordSuccessPage />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <PublicRoute>
                  <RequestPasswordResetPage />
                </PublicRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/token-login"
              element={
                <PublicRoute>
                  <TokenLoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <RegisterPage />
                </PublicRoute>
              }
            />
            <Route
              path="/retries"
              element={
                <PrivateRoute allowedRoles={['super-admin']}>
                  <DeadLetterEventsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/await-email-verification"
              element={
                <AwaitEmailVerificationRoute>
                  <AwaitEmailVerificationPage />
                </AwaitEmailVerificationRoute>
              }
            />
          </Route>
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default memo(App)
