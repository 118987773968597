/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, memo, forwardRef } from 'react'
import _ from 'lodash'

const saveIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#3d424e"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>'

const closeIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#3d424e"><path d="M0 0h24v24H0z" fill="none"/><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>'

const loadingIcon = `
<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="25 25 50 50" enable-background="new 0 0 0 0" xml:space="preserve">
    <path fill="#3d424e" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="1s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
  </path>
</svg>`
const Container = memo(
  forwardRef<HTMLDivElement, any>((props, ref) => {
    return <div ref={ref} style={{ width: '100%', height: '100vh' }} />
  }),
)
const PdfViewerComponent = ({
  instantJSON,
  onSave,
  onClose,
  saving,
}: {
  saving: boolean
  instantJSON: any
  onSave: (pdf: Blob, instantJSON: Blob) => Promise<void>
  onClose: (hasChanges: boolean) => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const PSPDFKitRef = useRef<any>()
  const instanceRef = useRef<any>()
  const prevInstantJSONRef = useRef<string>()
  const saveEnabledRef = useRef<boolean>(false)

  useEffect(() => {
    const container = containerRef.current
    ;(async function () {
      if (!PSPDFKitRef.current) {
        PSPDFKitRef.current = await import('pspdfkit')
      }
      const types = [
        'ink',
        'highlighter',
        'ink-eraser',
        'signature',
        'image',
        'text',

        'line',
        'arrow',
        'rectangle',
        'ellipse',
        'polygon',
        'polyline',
        'export-pdf',
        'undo',
        'debug',
      ]
      const createToolbarItems = () => {
        const toolbarItems = PSPDFKitRef.current.defaultToolbarItems.filter(({ type }) =>
          types.includes(type),
        )
        toolbarItems.push({
          type: 'undo',
        })
        toolbarItems.push({
          type: 'redo',
        })
        toolbarItems.push({
          type: 'spacer',
        })
        toolbarItems.push({
          type: 'custom',
          id: 'close-template',
          icon: closeIcon,
          title: 'Close template editor',
          onPress: async () => {
            onClose(saveEnabledRef.current)
          },
        })
        toolbarItems.push({
          type: 'custom',
          id: 'save-template',
          icon: saving ? loadingIcon : saveIcon,
          title: 'Save template',
          disabled: !saveEnabledRef.current,
          onPress: async () => {
            const buffer = await instanceRef.current.exportPDF()
            const pdfBlob = new Blob([buffer], { type: 'application/pdf' })

            // const fileName = 'document.pdf'
            // if ((window.navigator as any).msSaveOrOpenBlob) {
            //   ;(window.navigator as any).msSaveOrOpenBlob(blob, fileName)
            // } else {
            //   const objectUrl = URL.createObjectURL(blob)
            //   const a = document.createElement('a')
            //   a.href = objectUrl
            //   a.setAttribute('style', 'display: none')
            //   // a.style = 'display: none'
            //   a.download = fileName
            //   document.body.appendChild(a)
            //   a.click()
            //   URL.revokeObjectURL(objectUrl)
            //   document.body.removeChild(a)
            // }
            const instantJSON = _.omit(await instanceRef.current.exportInstantJSON(), 'pdfId')
            const instantJSONBlob = new Blob([JSON.stringify(instantJSON)], {
              type: 'application/json',
            })
            onSave(pdfBlob, instantJSONBlob)
            saveEnabledRef.current = false
            instanceRef.current.setToolbarItems(createToolbarItems())
          },
        })
        return toolbarItems
      }

      const addressWidget = document.createElement('div')
      addressWidget.innerHTML = `
        <div style="width: 281px; height: 129px; border: 2px solid pink; background-color: white"/>
      `
      const bleedMarginsVerticalBorderLineLeftDiv = document.createElement('div')
      bleedMarginsVerticalBorderLineLeftDiv.innerHTML = `
        <div style="width: 9px; height: 859px; background-color: pink; opacity: 0.3;"/>
      `
      const bleedMarginsVerticalBorderLineRightDiv = document.createElement('div')
      bleedMarginsVerticalBorderLineRightDiv.innerHTML = `
        <div style="width: 9px; height: 859px; background-color: pink; opacity: 0.3;"/>
      `
      const bleedMarginsHorizontalBorderLineTopDiv = document.createElement('div')
      bleedMarginsHorizontalBorderLineTopDiv.innerHTML = `
        <div style="width: 612px; height: 9px; background-color: pink; opacity: 0.3;"/>
      `
      const bleedMarginsHorizontalBorderLineBottomDiv = document.createElement('div')
      bleedMarginsHorizontalBorderLineBottomDiv.innerHTML = `
        <div style="width: 612px; height: 9px; background-color: pink; opacity: 0.3;"/>
      `
      const safeAreaVerticalBorderLineLeftDiv = document.createElement('div')
      safeAreaVerticalBorderLineLeftDiv.innerHTML = `
        <div style="width: 0px; height: 826px; border-left: 1px dashed green"/>
      `
      const safeAreaVerticalBorderLineRightDiv = document.createElement('div')
      safeAreaVerticalBorderLineRightDiv.innerHTML = `
        <div style="width: 0px; height: 826px; border-right: 1px dashed green"/>
      `
      const safeAreaHorizontalBorderLineTopDiv = document.createElement('div')
      safeAreaHorizontalBorderLineTopDiv.innerHTML = `
        <div style="width: 578px; height: 0px; border-top: 1px dashed green"/>
      `
      const safeAreaHorizontalBorderLineBottomDiv = document.createElement('div')
      safeAreaHorizontalBorderLineBottomDiv.innerHTML = `
        <div style="width: 578px; height: 8px; border-bottom: 1px dashed green"/>
      `
      const item = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'address-area',
        node: addressWidget,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 60, y: 124 }),
      })
      const bleedMarginsVerticalBorderLineLeft = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'bleed-margins-left',
        node: bleedMarginsVerticalBorderLineLeftDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 0, y: 0 }),
      })
      const bleedMarginsVerticalBorderLineRight = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'bleed-margins-right',
        node: bleedMarginsVerticalBorderLineRightDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 604, y: 0 }),
      })
      const bleedMarginsHorizontalBorderLineTop = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'bleed-margins-top',
        node: bleedMarginsHorizontalBorderLineTopDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 0, y: 0 }),
      })
      const bleedMarginsHorizontalBorderLineBottom = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'bleed-margins-bottom',
        node: bleedMarginsHorizontalBorderLineBottomDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 0, y: 850 }),
      })
      const safeAreaVerticalBorderLineLeft = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'safe-area-left',
        node: safeAreaVerticalBorderLineLeftDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 17, y: 17 }),
      })
      const safeAreaVerticalBorderLineRight = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'safe-area-right',
        node: safeAreaVerticalBorderLineRightDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 594, y: 17 }),
      })
      const safeAreaHorizontalBorderLineTop = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'safe-area-top',
        node: safeAreaHorizontalBorderLineTopDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 17, y: 17 }),
      })
      const safeAreaHorizontalBorderLineBottom = new PSPDFKitRef.current.CustomOverlayItem({
        id: 'safe-area-bottom',
        node: safeAreaHorizontalBorderLineBottomDiv,
        pageIndex: 0,
        position: new PSPDFKitRef.current.Geometry.Point({ x: 17, y: 835 }),
      })
      if (!instanceRef.current && prevInstantJSONRef.current !== instantJSON) {
        prevInstantJSONRef.current = instantJSON
        PSPDFKitRef.current.unload(container)
        const instance = await PSPDFKitRef.current.load({
          // licenseKey:
          //   'gdDnh80regHOJm8eaeBw7DqmdkQEHedYZQnC-R2KQ5_9Rqzqh6bLSOiad-a8fJ_aT3NHqn8kc5k4OA85RYU-PvY2Nb_R2g8VEiE6UFoRcnhRIJpu1qkGmqrW9EHpBbhA9vJjpbTlYZA0RpBhWpNmMO1UiviG4hHjY1r93qu81vip3asRfuhAnKRmXSmG_WUVraaT0ei7j46tMHjX9U8pbAn8DaW9-Qv-2S_AIuUVyRvNVFPQBiQgmRtaBdiGw0UsvBsT-5vfJn8Xe_nGCFxTo099Lvi4t9JW6b3Xg9bGwN-L8Lzb1_c4QN4EaWEbp1G-kOM-kDM_1OkvgZ9xJwO4mP6dtDjCfR0Fb_0Sl2bG1_c0AzuyLaQrO5JJUMfd3WTz4rEWmbktI-oCd1iCRQRVyKB0l7s67JijheQw6N-PZ-EeBpPdlveNk70n3CJmqZIcb6leR_tQPYdTgEkTI1UXyjv_tb0olduPOjDB1XV9ZP_hDscwYSpBB2213nPYDkjE0pp-IdKdmtxN2hOJoaGJ6cCTYVdbuNU44HZeuA6gJqnhWrhOGHlWVTUKt1DmEI_y-7Zj9JAC4j9gcvP8LxKbFw==',
          toolbarItems: createToolbarItems(),
          // Container where PSPDFKit should be mounted.
          container,
          // The document to open.
          document: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/blank.pdf`,
          instantJSON,
          enableHistory: true,
          // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        })
        // @ts-expect-error psPdfKitInstance is a global
        window.pspdfkitInstance = instance
        instance.setCustomOverlayItem(item)

        instance.setCustomOverlayItem(bleedMarginsVerticalBorderLineLeft)
        instance.setCustomOverlayItem(bleedMarginsVerticalBorderLineRight)
        instance.setCustomOverlayItem(bleedMarginsHorizontalBorderLineTop)
        instance.setCustomOverlayItem(bleedMarginsHorizontalBorderLineBottom)

        instance.setCustomOverlayItem(safeAreaVerticalBorderLineLeft)
        instance.setCustomOverlayItem(safeAreaVerticalBorderLineRight)
        instance.setCustomOverlayItem(safeAreaHorizontalBorderLineTop)
        instance.setCustomOverlayItem(safeAreaHorizontalBorderLineBottom)
        instance.addEventListener('annotations.create', () => {
          if (!saveEnabledRef.current) {
            saveEnabledRef.current = true
            instanceRef.current.setToolbarItems(createToolbarItems())
          }
        })
        instance.addEventListener('annotations.update', () => {
          if (!saveEnabledRef.current) {
            saveEnabledRef.current = true
            instanceRef.current.setToolbarItems(createToolbarItems())
          }
        })
        instanceRef.current = instance
      } else if (instanceRef.current) {
        instanceRef.current.setToolbarItems(createToolbarItems())
      }
    })()
  }, [saving, onClose, onSave, instantJSON])
  useEffect(() => {
    const container = containerRef.current
    return () => {
      // @ts-expect-error psPdfKitInstance is a global
      window.pspdfkitInstance = undefined
      return PSPDFKitRef.current && PSPDFKitRef.current.unload(container)
    }
  }, [])
  return <Container ref={containerRef} />
}

export default memo(PdfViewerComponent)
