import { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import postcodeDistrictsForm from '../../../forms/postcodeDistrictsForm'
import {
  PostcodeDistrict,
  postcodeDistricts as postcodeDistrictOptions,
} from '../../../models/PostcodeDistrict'
import { Autocomplete, TextField } from '@mui/material'
import DistrictMap from '../../atoms/DistrictMap'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'

const defaultPostcodeDistricts = []

const PostcodeDistrictsOnboardingPanel: FC<{
  onSave?: () => void
  loading: boolean
  companyId: string | null
  postcodeDistricts: PostcodeDistrict[] | null
}> = ({ onSave, loading, companyId, postcodeDistricts }) => {
  const { control, handleSubmit, formState, reset, setError, watch } = useForm<{
    postcodeDistricts: PostcodeDistrict[]
  }>({
    mode: 'onTouched',
    defaultValues: {
      postcodeDistricts: postcodeDistricts || defaultPostcodeDistricts,
    },
  })
  const formPostcodeDistricts = watch('postcodeDistricts')
  useEffect(() => {
    reset(
      {
        postcodeDistricts: postcodeDistricts || defaultPostcodeDistricts,
      },
      { keepDirtyValues: true },
    )
  }, [postcodeDistricts, reset])
  const onSubmit: SubmitHandler<{
    postcodeDistricts: PostcodeDistrict[]
  }> = useCallbackDebounced(
    ({ postcodeDistricts }: { postcodeDistricts: PostcodeDistrict[] }) => {
      if (companyId == null) {
        return
      }
      return postcodeDistrictsForm
        .onSubmit({
          companyId,
          postcodeDistricts,
        })
        .then(() => {
          reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false })
          onSave && onSave()
        })
        .catch((error: Error) => {
          setError('root', { message: error.message })
        })
    },
    [companyId, reset, setError, onSave],
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="postcodeDistricts"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Autocomplete
                {...field}
                onChange={(_, values) => {
                  field.onChange(values)
                }}
                multiple
                options={postcodeDistrictOptions}
                filterSelectedOptions
                renderInput={params => (
                  <TextField {...params} label="Postcodes" placeholder="Start typing a post code" />
                )}
              />
              <DistrictMap postcodeDistricts={field.value} onChange={field.onChange} />
              <div>{fieldState.error?.message}</div>
            </>
          )}
          rules={{
            validate: postcodeDistrictsForm.validatePostcodeDistrictsField,
          }}
        />
        <button type="submit" disabled={formState.isSubmitting || !formPostcodeDistricts.length}>
          save
        </button>
        {formState.errors.root?.message && <div>{formState.errors.root.message}</div>}
      </form>
      {`loading ${loading} `}
    </>
  )
}

export default PostcodeDistrictsOnboardingPanel
