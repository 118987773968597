import { useRef, useCallback } from 'react'

/**
 * Custom hook that wraps an unstable callback inside a stable wrapper callback.
 * Useful to e.g. keep an event handler prop stable when passing it down.
 *
 * @param {Function} callback - unstable callback to be wrapped
 * @param {Array} deps - list of values whose change will update the unstable callback reference (see React.useCallback)
 * @returns {Function} stable wrapped callback
 *
 * @example
 * // `handleClick` reference will not change even if deps change:
 * const handleClick = useStableCallback((event) => {
 *   console.log(someDep, event.target)
 * }, [someDep])
 */
const useStableCallback = <T>(callback: T) => {
  const ref = useRef<T>(callback)
  ref.current = callback
  // return stable wrapped callback
  return useCallback(
    (...args) => {
      // @ts-expect-error arg types irrelevant due to casting later
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return ref.current(...args)
    },
    [ref],
  ) as T
}

export default useStableCallback
