import companyService from '../../services/companyService'
import validators from '../validators'
import { PostcodeDistrict, postcodeDistricts } from '../../models/PostcodeDistrict'

class PostcodeDistrictsForm {
  onSubmit = async (values: {
    companyId: string
    postcodeDistricts: PostcodeDistrict[]
  }): Promise<void> => {
    const { companyId, postcodeDistricts } = values
    return companyService.updatePostcodeDistricts({
      companyId,
      postcodeDistricts,
    })
  }
  validatePostcodeDistrictsField = validators.combineValidators(
    validators.validateRequired({
      errorMessage: 'Please enter one or more postcode districts e.g. E17',
    }),
    validators.validateOptionsMulti<PostcodeDistrict>({
      errorMessage: 'Values must be a postcode district',
      options: postcodeDistricts,
    }),
  )
}

export default new PostcodeDistrictsForm()
