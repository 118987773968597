import axios from 'axios'

class GeojsonService {
  private _cache: Promise<Cache> | undefined
  private get cache() {
    if (!this._cache) {
      this._cache = caches.open('geo-json-cache')
    }
    return this._cache
  }
  private localCache = {
    '/districts.json': undefined,
    '/gb.json': undefined,
    '/places.json': undefined,
    '/roads.json': undefined,
  }

  private cachedFetch = async (
    url: '/districts.json' | '/gb.json' | '/places.json' | '/roads.json',
  ) => {
    const cache = await this.cache
    if (this.localCache[url]) {
      return this.localCache[url]
    }
    const cachedResponse = await cache.match(url)
    if (cachedResponse) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cachedData = await cachedResponse.json() // Return the cached JSON data
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.localCache[url] = cachedData
      return cachedData
    }
    const response = await axios.get(url)
    if (response.status === 200) {
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' })
      const cacheResponse = new Response(blob)
      await cache.put(url, cacheResponse)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.localCache[url] = response.data
      return response.data
    }
    throw new Error('No data available for ' + url)
  }

  fetchGeojson = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [districts, gb, places, roads] = await Promise.all([
      this.cachedFetch('/districts.json'),
      this.cachedFetch('/gb.json'),
      this.cachedFetch('/places.json'),
      this.cachedFetch('/roads.json'),
    ])
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      districts,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      gb,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      places,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      roads,
    }
  }
}

export default new GeojsonService()
