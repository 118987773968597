import {
  onSnapshot,
  Unsubscribe,
  QueryDocumentSnapshot,
  DocumentData,
  Query,
  DocumentReference,
  DocumentSnapshot,
} from 'firebase/firestore'

const noop = () => {}

abstract class BaseService {
  private createDeferred = <T>() => {
    const innerState = {
      resolved: false,
      rejected: false,
    }
    // @ts-expect-error promise is added later
    const deferred: {
      resolve: (value: T) => void
      reject: (error: Error) => void
      promise: Promise<T>
      getIsResolved: () => boolean
      getIsRejected: () => boolean
      getIsComplete: () => boolean
    } = {
      resolve: noop,
      reject: noop,
      getIsResolved: () => {
        return innerState.resolved
      },
      getIsRejected: () => {
        return innerState.rejected
      },
      getIsComplete: () => {
        return innerState.resolved || innerState.rejected
      },
    }
    deferred.promise = new Promise<T>((resolvePromise, rejectPromise) => {
      deferred.resolve = value => {
        innerState.resolved = true
        resolvePromise(value)
      }
      deferred.reject = (error: Error) => {
        innerState.rejected = true
        rejectPromise(error)
      }
    })
    return deferred
  }
  protected onSnapshot = <T>({
    query,
    onAdded = () => {},
    onUpdated = () => {},
    onRemoved = () => {},
  }: {
    query: Query<T, DocumentData>
    onAdded?: (data: T) => void
    onUpdated?: (data: T) => void
    onRemoved?: (data: T) => void
  }) => {
    const { promise, resolve, reject, getIsComplete } = this.createDeferred<{
      data: T[]
      docs: QueryDocumentSnapshot<T, DocumentData>[]
      unsubscribe: Unsubscribe
    }>()
    const unsubscribe = onSnapshot(
      query,
      snapshot => {
        if (!snapshot) {
          return
        }
        if (!getIsComplete()) {
          if (!snapshot.docs) {
            unsubscribe()
          }
          resolve({
            docs: snapshot.docs,
            data: snapshot.docs.map(doc => doc.data()),
            unsubscribe,
          })
        } else {
          snapshot.docChanges().forEach(change => {
            if (change.doc.metadata.hasPendingWrites) {
              return
            }
            if (change.type === 'added' && onAdded) {
              onAdded(change.doc.data())
            }
            if (change.type === 'modified' && onUpdated) {
              onUpdated(change.doc.data())
            }
            if (change.type === 'removed' && onRemoved) {
              onRemoved(change.doc.data())
            }
          })
        }
      },
      (error: Error) => {
        if (!getIsComplete()) {
          unsubscribe()
          reject(error)
        }
      },
    )
    return promise
  }
  protected onDoc = <T>({
    ref,
    onUpdated = () => {},
    onRemoved = () => {},
  }: {
    ref: DocumentReference<T, DocumentData>
    onUpdated?: (data: T) => void
    onRemoved?: (data: T) => void
  }) => {
    const { promise, resolve, reject, getIsComplete } = this.createDeferred<{
      data: T | null
      doc: DocumentSnapshot<T, DocumentData>
      unsubscribe: Unsubscribe
    }>()
    const unsubscribe = onSnapshot(
      ref,
      (snapshot: DocumentSnapshot<T, DocumentData>) => {
        if (!snapshot) {
          return
        }
        if (!getIsComplete()) {
          if (!snapshot.exists()) {
            unsubscribe()
            resolve({
              doc: snapshot,
              data: null,
              unsubscribe,
            })
          } else {
            resolve({
              doc: snapshot,
              data: snapshot.data() as T,
              unsubscribe,
            })
          }
        } else {
          if (!snapshot.exists()) {
            onRemoved(snapshot.data() as T)
            return
          }
          onUpdated(snapshot.data())
        }
      },
      (error: Error) => {
        if (!getIsComplete()) {
          unsubscribe()
          reject(error)
        }
      },
    )
    return promise
  }
}

export default BaseService
