import './polyfills/promiseWithResolvers'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './initialiseFirebase'
import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createRoot } from 'react-dom/client'
import { ReduxProvider } from './redux/ReduxProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SnackbarProvider } from './components/providers/SnackbarProvider'
import { OnboardingProvider } from './components/providers/OnboardingProvider'
import { CreateTemplateDialogProvider } from './components/providers/CreateTemplateDialogProvider'
import { PostcodeDistrictsDialogProvider } from './components/providers/PostcodeDistrictsDialogProvider'
import { PlanningCategoriesDialogProvider } from './components/providers/PlanningCategoriesDialogProvider'
import { SubscriptionDialogProvider } from './components/providers/SubscriptionDialogProvider'

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <>
    <CssBaseline />
    <ReduxProvider>
      <SnackbarProvider>
        <OnboardingProvider>
          <CreateTemplateDialogProvider>
            <PostcodeDistrictsDialogProvider>
              <PlanningCategoriesDialogProvider>
                <SubscriptionDialogProvider>
                  <App />
                </SubscriptionDialogProvider>
              </PlanningCategoriesDialogProvider>
            </PostcodeDistrictsDialogProvider>
          </CreateTemplateDialogProvider>
        </OnboardingProvider>
      </SnackbarProvider>
    </ReduxProvider>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
