import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import config from './config'

export const app = initializeApp(config.firebaseConfig)

if (config.localFunctions) {
  connectFunctionsEmulator(getFunctions(app), '127.0.0.1', 5001)
  connectFunctionsEmulator(getFunctions(app, 'europe-west2'), '127.0.0.1', 5001)
}
if (config.localFirestore) {
  connectFirestoreEmulator(getFirestore(app), '127.0.0.1', 8080)
}
if (config.localAuth) {
  connectAuthEmulator(getAuth(app), 'http://127.0.0.1:7549')
}
if (config.localStorage) {
  connectStorageEmulator(getStorage(app), '127.0.0.1', 9199)
}
