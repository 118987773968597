import { createSlice } from '@reduxjs/toolkit'
import { CompanyPlanningRecord } from '../../../models/CompanyPlanningRecord'
import authActions from '../auth/actions'
import actions from './actions'

export interface CompanyPlanningRecordsState {
  companyPlanningRecords: CompanyPlanningRecord[]
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: CompanyPlanningRecordsState = {
  companyPlanningRecords: [],
  status: 'initial',
}

export const companyPlanningRecordsSlice = createSlice({
  name: 'companyPlanningRecords',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.loadCompanyPlanningRecordsFresh.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadCompanyPlanningRecordsFresh.fulfilled, (state, action) => {
        state.status = 'idle'
        state.companyPlanningRecords = action.payload.data
        state.error = undefined
      })
      .addCase(actions.loadCompanyPlanningRecordsFresh.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.loadCompanyPlanningRecordsNext.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadCompanyPlanningRecordsNext.fulfilled, (state, action) => {
        state.status = 'idle'
        state.companyPlanningRecords.push(...action.payload.data)
        state.error = undefined
      })
      .addCase(actions.loadCompanyPlanningRecordsNext.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.updateCompanyPlanningRecord, (state, action) => {
        for (let i = 0, length = state.companyPlanningRecords.length; i < length; i++) {
          const companyPlanningRecord = state.companyPlanningRecords[i]
          if (companyPlanningRecord.id === action.payload.id) {
            state.companyPlanningRecords[i] = action.payload
            break
          }
        }
      })
  },
})

export default companyPlanningRecordsSlice
