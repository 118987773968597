import { useRef, useCallback, DependencyList, useEffect } from 'react'

// tailing debounce - callback is called only after the specified delay has elapsed to prevent spam
// called after delay
//  - if called lots of times initially, waits to settle before making initial call
//  - if called again while executing caches args and calls them after execution finishes
//  - if called again right after executing then delay is applied again

const useCallbackDebouncedAsync = <T extends (...args: any[]) => any>(
  fn: T,
  deps: DependencyList,
  delay = 300,
): T => {
  const executingRef = useRef(false)
  const timerRef = useRef<NodeJS.Timeout>()
  const deferedRef = useRef<PromiseWithResolvers<ReturnType<T>>>()
  const pendingArgsRef = useRef<Parameters<T>>()
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
      deferedRef.current = undefined
      pendingArgsRef.current = undefined
      executingRef.current = false
    }
  }, [])
  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    const executeCallback = (...args: Parameters<T>) => {
      executingRef.current = true
      return Promise.resolve(fn(...args))
        .then((res: ReturnType<T>) => {
          if (pendingArgsRef.current) {
            executeCallback(...pendingArgsRef.current)
            pendingArgsRef.current = undefined
          } else {
            deferedRef.current?.resolve(res)
            deferedRef.current = undefined
            executingRef.current = false
          }
        })
        .catch((error: Error) => {
          if (pendingArgsRef.current) {
            executeCallback(...pendingArgsRef.current)
            pendingArgsRef.current = undefined
          } else {
            deferedRef.current?.reject(error)
            deferedRef.current = undefined
            executingRef.current = false
          }
        })
    }
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      timerRef.current = undefined
      if (executingRef.current) {
        pendingArgsRef.current = args
      } else {
        executeCallback(...args)
      }
    }, delay)
    if (!deferedRef.current) {
      deferedRef.current = Promise.withResolvers()
    }
    return deferedRef.current.promise as ReturnType<T>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps) as T
}

export default useCallbackDebouncedAsync
