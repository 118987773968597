import { useState, Dispatch, SetStateAction, useRef, MutableRefObject, useCallback } from 'react'

function useStateWithRef<T>(
  initialState: T | (() => T),
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>]

// @ts-expect-error function overloading
function useStateWithRef<T = undefined>(): [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
  MutableRefObject<T | undefined>,
]

function useStateWithRef<T>(value: T) {
  const [stateValue, setStateValueBase] = useState(value)
  const ref = useRef(stateValue)
  ref.current = stateValue
  const setStateValue: Dispatch<SetStateAction<T>> = useCallback((param: SetStateAction<T>) => {
    if (typeof param === 'function') {
      setStateValueBase(param)
    } else {
      ref.current = param
      setStateValueBase(param)
    }
  }, [])
  return [stateValue, setStateValue, ref]
}

export { useStateWithRef }
