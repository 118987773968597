import companyService from '../../services/companyService'
import validators from '../validators'

class MonthlyCreditLimitForm {
  onSubmit = async (values: { companyId: string; monthlyCreditLimit: number }): Promise<void> => {
    const { companyId, monthlyCreditLimit } = values
    return companyService.updateMonthlyCreditLimit({
      companyId,
      monthlyCreditLimit,
    })
  }
  validateMonthlyCreditLimitField = validators.combineValidators(
    validators.validateNumber({
      errorMessage: 'Please enter the number of credits you want to top up per month.',
    }),
  )
}

export default new MonthlyCreditLimitForm()
