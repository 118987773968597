import { forwardRef } from 'react'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

// eslint-disable-next-line react/display-name
const DialogSlideRightToLeftTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="left" ref={ref} {...props} />
  },
)

export default DialogSlideRightToLeftTransition
