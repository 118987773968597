import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
  FC,
  PropsWithChildren,
} from 'react'
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe, StripeElements, StripeElementsOptions } from '@stripe/stripe-js'
import config from '../../../config'
import { Control, FieldValues, FormState, SubmitHandler, useForm } from 'react-hook-form'
import subscribeForm from '../../../forms/subscribeForm'
import unsubscribeForm from '../../../forms/unsubscribeForm'
import topupForm from '../../../forms/topupForm'
import monthlyCreditLimitForm from '../../../forms/monthlyCreditLimitForm'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import useCallbackDebouncedAsync from '../../../hooks/shared/useCallbackDebouncedAsync'
import useStableCallback from '../../../hooks/shared/useStableCallback'
import { useAutoSave } from '../../../hooks/form/useAutosave'
import { useSnackbar } from '../../providers/SnackbarProvider'
import { useStateWithRef } from '../../../hooks/shared/useStateWithRef'
import { useSelector, useDispatch } from '../../../redux/hooks'
import companySelectors from '../../../redux/modules/company/selectors'
import companyActions from '../../../redux/modules/company/actions'
import authSelectors from '../../../redux/modules/auth/selectors'
import _ from 'lodash'

let stripePromise: Promise<Stripe | null>

const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripeSecret)
  }
  return stripePromise
}

const withStripeProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    return (
      <Elements
        stripe={getStripe()}
        options={
          {
            mode: 'subscription',
            amount: 0,
            currency: 'gbp',
            paymentMethodCreation: 'manual',
            payment_method_types: ['card'],
          } as StripeElementsOptions
        }
      >
        <Component {...(props as P)} />
      </Elements>
    )
  }

type SubscriptionState =
  | 'LOADING'
  | 'UNSUBSCRIBED'
  | 'SUBSCRIBED'
  | 'PROCESSING_PAYMENT'
  | 'PROCESSING_SUBSCRIPTION'
  | 'PAYMENT_COMPLETE'
  | 'SUBSCRIPTION_COMPLETE'

const SubscriptionContext = createContext<{
  credits: number | null
  pendingCredits: number | null
  onHandleSubmitSubscribe: () => void
  onHandleSubmitUnsubscribe: () => void
  onHandleSubmitTopup: () => void
  monthlyCreditLimitControl: Control<
    {
      monthlyCreditLimit: number
    },
    any
  >
  formStateSubscribe: FormState<FieldValues>
  formStateUnsubscribe: FormState<FieldValues>
  formStateTopup: FormState<FieldValues>
  awaitingSubscription: boolean
  awaitingPayment: boolean
  subscriptionComplete: boolean
  paymentComplete: boolean
  additionalTopupAmount: number
  state: SubscriptionState
  processing: boolean
  subscribeDisabled: boolean
  unsubscribeDisabled: boolean
  topupDisabled: boolean
  monthlyCreditLimitDisabled: boolean
  topupError: string | undefined
  subscribeError: string | undefined
  unsubscribeError: string | undefined
  monthlyCreditLimitError: string | undefined
  monthlyCreditLimitSubmitting: boolean
  topupSubmitting: boolean
  subscribeSubmitting: boolean
  unsubscribeSubmitting: boolean
} | null>(null)

const useSubscriptionBase = () => {
  const context = useContext(SubscriptionContext)
  if (!context) {
    throw new Error('no SubscriptionProvider found when calling useSubscription()')
  }
  return context
}

const useSubscription = ({
  onPaymentComplete = _.noop,
}: { onPaymentComplete?: () => void } = {}) => {
  const subscription = useSubscriptionBase()
  const onPaymentCompleteStable = useStableCallback(onPaymentComplete)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    // trigger onPaymentComplete a couple seconds after payment received
    if (
      subscription.formStateSubscribe.isSubmitSuccessful &&
      (subscription.paymentComplete ||
        (subscription.subscriptionComplete && !subscription.awaitingPayment))
    ) {
      timeout = setTimeout(() => {
        onPaymentCompleteStable()
      }, 2500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [
    onPaymentCompleteStable,
    subscription.awaitingPayment,
    subscription.formStateSubscribe.isSubmitSuccessful,
    subscription.paymentComplete,
    subscription.subscriptionComplete,
  ])

  return subscription
}

const getState = ({
  loading,
  hasActiveSubscription,
  awaitingSubscription,
  awaitingPayment,
  paymentComplete,
  subscriptionComplete,
  isSubmitSuccessful,
}: {
  loading: boolean
  hasActiveSubscription: boolean
  awaitingSubscription: boolean
  awaitingPayment: boolean
  paymentComplete: boolean
  subscriptionComplete: boolean
  isSubmitSuccessful: boolean
}): SubscriptionState => {
  if (loading) {
    return 'LOADING'
  }
  if (isSubmitSuccessful && awaitingSubscription) {
    return 'PROCESSING_SUBSCRIPTION'
  }
  if (isSubmitSuccessful && awaitingPayment) {
    return 'PROCESSING_PAYMENT'
  }
  if (isSubmitSuccessful && paymentComplete) {
    return 'PAYMENT_COMPLETE'
  }
  if (isSubmitSuccessful && subscriptionComplete) {
    return 'SUBSCRIPTION_COMPLETE'
  }
  if (hasActiveSubscription) {
    return 'SUBSCRIBED'
  }
  return 'UNSUBSCRIBED'
}

const useGetPaymentComplete = () => {
  const [awaitingSubscription, setAwaitingSubscription, awaitingSubscriptionRef] =
    useStateWithRef<boolean>(false)
  const [awaitingPayment, setAwaitingPayment, awaitingPaymentRef] = useStateWithRef<boolean>(false)
  const [paymentComplete, setPaymentComplete] = useState<boolean>(false)
  const [subscriptionComplete, setSubscriptionComplete] = useState<boolean>(false)
  const subscriptionAwaitingSync = useSelector(companySelectors.getSubscriptionAwaitingSync)
  const paymentAwaitingSync = useSelector(companySelectors.getPaymentAwaitingSync)
  const awaitingSyncStartedRef = useRef(false)
  useEffect(() => {
    if ((subscriptionAwaitingSync || paymentAwaitingSync) && !awaitingSyncStartedRef.current) {
      awaitingSyncStartedRef.current = true
      if (subscriptionAwaitingSync) {
        setAwaitingSubscription(true)
      }
      if (paymentAwaitingSync) {
        setAwaitingPayment(true)
      }
      setPaymentComplete(false)
      setSubscriptionComplete(false)
      return
    }
    if (awaitingSyncStartedRef.current && (!subscriptionAwaitingSync || !paymentAwaitingSync)) {
      if (!subscriptionAwaitingSync && awaitingSubscriptionRef.current) {
        setAwaitingSubscription(false)
        setSubscriptionComplete(true)
      }
      if (!paymentAwaitingSync && awaitingPaymentRef.current) {
        setAwaitingPayment(false)
        setPaymentComplete(true)
      }
      if (!awaitingSubscriptionRef.current && !awaitingPaymentRef.current) {
        awaitingSyncStartedRef.current = false
      }
    }
  }, [
    awaitingPaymentRef,
    awaitingSubscriptionRef,
    paymentAwaitingSync,
    setAwaitingPayment,
    setAwaitingSubscription,
    subscriptionAwaitingSync,
  ])
  return { awaitingSubscription, awaitingPayment, subscriptionComplete, paymentComplete }
}

const SubscriptionProvider: FC<PropsWithChildren> = withStripeProvider(({ children }) => {
  const dispatch = useDispatch()
  const stripe = useStripe() as Stripe
  const elements = useElements() as StripeElements
  const loading = useSelector(companySelectors.getLoading)
  const companyId = useSelector(authSelectors.getCompanyId)
  const { awaitingSubscription, awaitingPayment, subscriptionComplete, paymentComplete } =
    useGetPaymentComplete()
  const monthlyCreditLimit = useSelector(companySelectors.getCompanyMonthlyCreditLimit)
  const creditsPurchased = useSelector(companySelectors.getCompanyCreditsPurchased)
  const hasActiveSubscription = useSelector(companySelectors.getHasActiveSubscription)
  const purchaseSubscription = useCallback(() => {
    dispatch(companyActions.purchaseSubscription())
  }, [dispatch])
  const cancelSubscription = useCallback(() => {
    dispatch(companyActions.cancelSubscription())
  }, [dispatch])
  const topup = useCallback(() => {
    dispatch(companyActions.topup())
  }, [dispatch])
  const { showSuccess } = useSnackbar()
  const [additionalTopupAmount, setAdditionalTopupAmount, additionalTopupAmountRef] =
    useStateWithRef<number>(Math.max((monthlyCreditLimit || 0) - (creditsPurchased || 0), 0))
  const {
    reset: resetSubscribeForm,
    handleSubmit: handleSubmitSubscribe,
    formState: formStateSubscribe,
    setError: setErrorSubscribe,
  } = useForm()
  const {
    reset: resetUnsubscribeForm,
    handleSubmit: handleSubmitUnsubscribe,
    formState: formStateUnsubscribe,
    setError: setErrorUnsubscribe,
  } = useForm()
  const {
    reset: resetTopupForm,
    handleSubmit: handleSubmitTopup,
    formState: formStateTopup,
    setError: setErrorTopup,
  } = useForm()
  const {
    control,
    watch: watchMonthlyCreditLimit,
    handleSubmit: handleSubmitMonthlyCreditLimit,
    formState: formStateMonthlyCreditLimit,
    setError: setErrorMonthlyCreditLimit,
  } = useForm<{
    monthlyCreditLimit: number
  }>({
    mode: 'onChange',
    defaultValues: {
      monthlyCreditLimit: (monthlyCreditLimit || 0) as number,
    },
  })
  useEffect(() => {
    if (
      additionalTopupAmount > 0 &&
      !formStateMonthlyCreditLimit.isSubmitting &&
      formStateMonthlyCreditLimit.isSubmitSuccessful
    ) {
      showSuccess(
        `Successfully set letter limit. Would you like to purchase an additional ${additionalTopupAmount} credits for £${additionalTopupAmount * 3}?`,
      )
    }
  }, [
    additionalTopupAmount,
    formStateMonthlyCreditLimit.isSubmitSuccessful,
    formStateMonthlyCreditLimit.isSubmitting,
    showSuccess,
  ])
  const state = getState({
    loading,
    awaitingSubscription,
    awaitingPayment,
    hasActiveSubscription,
    isSubmitSuccessful: formStateSubscribe.isSubmitSuccessful,
    paymentComplete,
    subscriptionComplete,
  })
  const onSubmitMonthlyCreditLimit: SubmitHandler<{
    monthlyCreditLimit: number
  }> = useCallbackDebouncedAsync(
    async ({ monthlyCreditLimit }) => {
      if (!companyId) {
        throw new Error('Failed to submit. Please try again.')
      }
      await monthlyCreditLimitForm
        .onSubmit({ companyId, monthlyCreditLimit })
        .then(() => {
          if (state === 'SUBSCRIBED' && creditsPurchased) {
            setAdditionalTopupAmount(Math.max(monthlyCreditLimit - creditsPurchased, 0))
          } else {
            if (additionalTopupAmountRef.current > 0) {
              setAdditionalTopupAmount(0)
            }
          }
        })
        .catch((error: Error) => {
          setErrorMonthlyCreditLimit('root', { message: error.message })
        })
    },
    [
      additionalTopupAmountRef,
      companyId,
      creditsPurchased,
      setAdditionalTopupAmount,
      setErrorMonthlyCreditLimit,
      state,
    ],
    600,
  )
  const onSubmitSubscribe = useCallbackDebounced(async () => {
    if (!stripe) {
      setErrorSubscribe('root', { message: 'Payment form is not ready. Please try again shortly.' })
      return
    }
    await subscribeForm
      .onSubmit({ elements, stripe })
      .then(() => {
        purchaseSubscription()
      })
      .catch((error: Error) => {
        setErrorSubscribe('root', { message: error.message })
      })
  }, [elements, setErrorSubscribe, stripe, purchaseSubscription])
  const onSubmitUnsubscribe = useCallbackDebounced(async () => {
    await unsubscribeForm
      .onSubmit()
      .then(() => {
        cancelSubscription()
        setAdditionalTopupAmount(0)
        resetUnsubscribeForm()
      })
      .catch((error: Error) => {
        setErrorUnsubscribe('root', { message: error.message })
      })
  }, [setErrorUnsubscribe, cancelSubscription, resetUnsubscribeForm, setAdditionalTopupAmount])
  const onSubmitTopup = useCallbackDebounced(async () => {
    await topupForm
      .onSubmit()
      .then(() => {
        topup()
        setAdditionalTopupAmount(0)
        resetTopupForm()
      })
      .catch((error: Error) => {
        setErrorTopup('root', { message: error.message })
      })
  }, [setErrorTopup, topup, resetTopupForm, setAdditionalTopupAmount])
  const onHandleSubmitMonthlyCreditLimit = useStableCallback(
    handleSubmitMonthlyCreditLimit(onSubmitMonthlyCreditLimit),
  )
  const onHandleSubmitSubscribe = useStableCallback(handleSubmitSubscribe(onSubmitSubscribe))
  const onHandleSubmitUnsubscribe = useStableCallback(handleSubmitUnsubscribe(onSubmitUnsubscribe))
  const onHandleSubmitTopup = useStableCallback(handleSubmitTopup(onSubmitTopup))
  useAutoSave({
    watch: watchMonthlyCreditLimit,
    submit: onHandleSubmitMonthlyCreditLimit,
  })
  useEffect(() => {
    let timeout: NodeJS.Timeout
    // trigger resetSubscribeForm a couple seconds after payment received
    if (
      formStateSubscribe.isSubmitSuccessful &&
      (paymentComplete || (subscriptionComplete && !awaitingPayment))
    ) {
      timeout = setTimeout(() => {
        resetSubscribeForm()
      }, 2500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [
    awaitingPayment,
    formStateSubscribe.isSubmitSuccessful,
    paymentComplete,
    resetSubscribeForm,
    subscriptionComplete,
  ])
  const monthlyCreditLimitInForm = watchMonthlyCreditLimit('monthlyCreditLimit')
  const credits = useSelector(companySelectors.getCompanyCredits)
  const pendingCredits = useSelector(companySelectors.getCompanyPendingCredits)
  const value = useMemo(
    () => ({
      credits,
      pendingCredits,
      onHandleSubmitSubscribe,
      onHandleSubmitUnsubscribe,
      onHandleSubmitTopup,
      monthlyCreditLimitControl: control,
      formStateSubscribe,
      formStateUnsubscribe,
      formStateTopup,
      awaitingSubscription,
      awaitingPayment,
      subscriptionComplete,
      paymentComplete,
      additionalTopupAmount,
      state,
      processing:
        !stripe ||
        loading ||
        formStateSubscribe.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        state === 'LOADING' ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      subscribeDisabled:
        !monthlyCreditLimitInForm ||
        !stripe ||
        loading ||
        formStateSubscribe.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'LOADING' ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      unsubscribeDisabled:
        !stripe ||
        loading ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'LOADING' ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      topupDisabled:
        !stripe ||
        loading ||
        formStateTopup.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateMonthlyCreditLimit.isSubmitting ||
        !!formStateMonthlyCreditLimit.errors.root?.message ||
        state === 'LOADING' ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      monthlyCreditLimitDisabled:
        loading ||
        formStateSubscribe.isSubmitting ||
        formStateUnsubscribe.isSubmitting ||
        formStateTopup.isSubmitting ||
        state === 'LOADING' ||
        state === 'PROCESSING_PAYMENT' ||
        state === 'PAYMENT_COMPLETE' ||
        state === 'SUBSCRIPTION_COMPLETE' ||
        state === 'PROCESSING_SUBSCRIPTION',
      topupError: formStateTopup.errors.root?.message,
      subscribeError: formStateSubscribe.errors.root?.message,
      unsubscribeError: formStateUnsubscribe.errors.root?.message,
      monthlyCreditLimitError: formStateMonthlyCreditLimit.errors.root?.message,
      monthlyCreditLimitSubmitting: formStateMonthlyCreditLimit.isSubmitting,
      topupSubmitting: formStateTopup.isSubmitting,
      subscribeSubmitting: formStateSubscribe.isSubmitting,
      unsubscribeSubmitting: formStateUnsubscribe.isSubmitting,
    }),
    [
      credits,
      pendingCredits,
      monthlyCreditLimitInForm,
      additionalTopupAmount,
      awaitingPayment,
      awaitingSubscription,
      control,
      formStateMonthlyCreditLimit.errors.root?.message,
      formStateMonthlyCreditLimit.isSubmitting,
      formStateSubscribe,
      formStateTopup,
      formStateUnsubscribe,
      loading,
      onHandleSubmitSubscribe,
      onHandleSubmitTopup,
      onHandleSubmitUnsubscribe,
      paymentComplete,
      state,
      stripe,
      subscriptionComplete,
    ],
  )
  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>
})

export { useSubscription, SubscriptionProvider }
export default SubscriptionProvider
