import { createSlice } from '@reduxjs/toolkit'
import { PeriodReport } from '../../../models/PeriodReport'
import authActions from '../auth/actions'
import actions from './actions'
import { CompanyRankReport } from '../../../models/CompanyRankReport'
import { CompanyReportInfo } from '../../../models/CompanyReportInfo'
import { PendingDeadLetterCounter } from '../../../models/PendingDeadLetterCounter'

export interface AdminDashboardState {
  pendingDeadLetterCounter?: PendingDeadLetterCounter
  today?: PeriodReport
  yesterday?: PeriodReport
  thisMonth?: PeriodReport
  lastMonth?: PeriodReport
  allTime?: PeriodReport
  companyRankAllTime?: CompanyRankReport
  companyRankThisMonth?: CompanyRankReport
  companyRankLastMonth?: CompanyRankReport
  companyReportInfo?: Record<string, CompanyReportInfo>
  currentRequest?: string
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: AdminDashboardState = {
  pendingDeadLetterCounter: undefined,
  today: undefined,
  yesterday: undefined,
  thisMonth: undefined,
  lastMonth: undefined,
  allTime: undefined,
  companyRankAllTime: undefined,
  companyRankThisMonth: undefined,
  companyRankLastMonth: undefined,
  companyReportInfo: undefined,
  currentRequest: undefined,
  status: 'initial',
}

export const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.loadDashboard.pending, (state, action) => {
        state.currentRequest = action.meta.requestId
        state.status = 'loading'
      })
      .addCase(actions.loadDashboard.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.pendingDeadLetterCounter = action.payload.pendingDeadLetterCounterData.data
          state.today = action.payload.dashboardData.data.today
          state.yesterday = action.payload.dashboardData.data.yesterday
          state.thisMonth = action.payload.dashboardData.data.thisMonth
          state.lastMonth = action.payload.dashboardData.data.lastMonth
          state.allTime = action.payload.dashboardData.data.allTime
          state.companyRankAllTime = action.payload.companyRankingData.data.allTime
          state.companyRankThisMonth = action.payload.companyRankingData.data.thisMonth
          state.companyRankLastMonth = action.payload.companyRankingData.data.lastMonth
          state.companyReportInfo = action.payload.companyInfoData.data.reduce<
            Record<string, CompanyReportInfo>
          >((memo, next) => {
            memo[next.id] = next
            return memo
          }, {})
          state.error = undefined
        } else {
          action.payload.dashboardData.unsubscribe()
          action.payload.companyRankingData.unsubscribe()
          action.payload.companyInfoData.unsubscribe()
        }
      })
      .addCase(actions.loadDashboard.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
      .addCase(actions.updateAllTimePeriodReport, (state, action) => {
        state.allTime = action.payload
      })
      .addCase(actions.updateLastMonthPeriodReport, (state, action) => {
        state.lastMonth = action.payload
      })
      .addCase(actions.updateThisMonthPeriodReport, (state, action) => {
        state.thisMonth = action.payload
      })
      .addCase(actions.updateTodayPeriodReport, (state, action) => {
        state.today = action.payload
      })
      .addCase(actions.updateYesterdayPeriodReport, (state, action) => {
        state.yesterday = action.payload
      })
      .addCase(actions.updateAllTimeCompanyRankReport, (state, action) => {
        state.companyRankAllTime = action.payload
      })
      .addCase(actions.updateLastMonthCompanyRankReport, (state, action) => {
        state.companyRankLastMonth = action.payload
      })
      .addCase(actions.updateThisMonthCompanyRankReport, (state, action) => {
        state.companyRankThisMonth = action.payload
      })
      .addCase(actions.updateCompanyReportInfo, (state, action) => {
        if (
          state.companyReportInfo?.[action.payload.id] &&
          (state.companyReportInfo[action.payload.id].defaultTemplateId !==
            action.payload.defaultTemplateId ||
            state.companyReportInfo[action.payload.id].defaultTemplateVersion !==
              action.payload.defaultTemplateVersion ||
            state.companyReportInfo[action.payload.id].accountEmail !== action.payload.accountEmail)
        ) {
          state.companyReportInfo[action.payload.id] = action.payload
        }
      })
      .addCase(actions.updatePendingDeadLetterCounter, (state, action) => {
        state.pendingDeadLetterCounter = action.payload
      })
  },
})

export default adminDashboardSlice
