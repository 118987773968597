import { FC, memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useCreateTemplateDialog } from '../../providers/CreateTemplateDialogProvider/CreateTemplateDialogProvider'
import { usePostcodeDistrictsDialog } from '../../providers/PostcodeDistrictsDialogProvider'
import { usePlanningCategoriesDialog } from '../../providers/PlanningCategoriesDialogProvider'
import { useOnboardingChecklist } from '../../providers/OnboardingProvider'
import { useSubscriptionDialog } from '../../providers/SubscriptionDialogProvider'

const Sidebar: FC = () => {
  const {
    loading: loadingOnboardingChecklist,
    missingPostCodeDistricts,
    missingPlanningCategories,
    missingTemplate,
    missingDefaultTemplateSubmitted,
    missingDefaultTemplateApproved,
    missingSubscription,
    showOnboardingChecklist,
  } = useOnboardingChecklist()
  const { openCreateTemplateDialog } = useCreateTemplateDialog()
  const { openPostcodeDistrictsDialog } = usePostcodeDistrictsDialog()
  const { openPlanningCategoriesDialog } = usePlanningCategoriesDialog()
  const { openSubscriptionDialog } = useSubscriptionDialog()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loadingOnboardingChecklist && (
        <Typography variant="caption" color="error">
          loading
        </Typography>
      )}
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-postcode-districts-icon'}>
              {!missingPostCodeDistricts ? '√' : 'X'}
            </span>{' '}
          </>
        )}
        coverage
      </Typography>
      <button onClick={openPostcodeDistrictsDialog}>edit postcode dstricts</button>
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-planning-categories-icon'}>
              {!missingPlanningCategories ? '√' : 'X'}
            </span>{' '}
          </>
        )}
        job types
      </Typography>
      <button onClick={openPlanningCategoriesDialog}>edit planning categories</button>
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-template-icon'}>{!missingTemplate ? '√' : 'X'}</span>{' '}
          </>
        )}
        create template
      </Typography>
      <button onClick={openCreateTemplateDialog}>create template</button>
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-subscription-icon'}>{!missingSubscription ? '√' : 'X'}</span>{' '}
          </>
        )}
        subscribe
      </Typography>
      <button onClick={openSubscriptionDialog}>manage subscription</button>
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-template-submitted-icon'}>
              {!missingDefaultTemplateSubmitted ? '√' : 'X'}
            </span>{' '}
          </>
        )}
        submit template
      </Typography>
      <Typography variant="caption">
        {showOnboardingChecklist && (
          <>
            <span id={'missing-template-approved-icon'}>
              {!missingDefaultTemplateApproved ? '√' : 'X'}
            </span>{' '}
          </>
        )}
        template approved
      </Typography>
    </Box>
  )
}

export default memo(Sidebar)
