import { useState, useMemo, useContext, createContext } from 'react'
import { Dialog } from '@mui/material'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import DialogSlideRightToLeftTransition from '../../atoms/DialogSlideRightToLeftTransition'
import CreateTemplatePanel from '../../molecules/CreateTemplatePanel'

const CreateTemplateDialogContext = createContext<{
  openCreateTemplateDialog: () => void
  closeCreateTemplateDialog: () => void
} | null>(null)

const useCreateTemplateDialog = () => {
  const context = useContext(CreateTemplateDialogContext)
  if (!context) {
    throw new Error('no CreateTemplateDialogProvider found when calling useCreateTemplateDialog()')
  }
  return context
}

const CreateTemplateDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [skipTransition, setSkipTransition] = useState(false)
  const openCreateTemplateDialog = useCallbackDebounced(() => {
    setSkipTransition(false)
    setOpen(true)
  }, [])
  const closeCreateTemplateDialog = useCallbackDebounced(() => {
    setOpen(false)
  }, [])
  const closeCreateTemplateDialogWithoutTransition = useCallbackDebounced(() => {
    setSkipTransition(true)
    setOpen(false)
  }, [])
  const value = useMemo(
    () => ({
      openCreateTemplateDialog,
      closeCreateTemplateDialog,
    }),
    [closeCreateTemplateDialog, openCreateTemplateDialog],
  )
  return (
    <CreateTemplateDialogContext.Provider value={value}>
      {children}
      <Dialog
        open={open}
        TransitionComponent={DialogSlideRightToLeftTransition}
        TransitionProps={{
          timeout: skipTransition ? 0 : { enter: 300, exit: 300 },
        }}
        onClose={closeCreateTemplateDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70vw',
            height: '100vh',
            width: 1000,
            position: 'fixed',
            top: 0,
            right: 0,
            margin: 0,
            maxHeight: 'none',
            borderRadius: 0,
          },
        }}
      >
        <CreateTemplatePanel
          onClose={closeCreateTemplateDialogWithoutTransition}
          onSave={closeCreateTemplateDialogWithoutTransition}
        />
      </Dialog>
    </CreateTemplateDialogContext.Provider>
  )
}

export { useCreateTemplateDialog, CreateTemplateDialogProvider }
export default CreateTemplateDialogProvider
