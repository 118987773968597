export type PlanningCategory =
  | 'tree-surgery'
  | 'kitchen-fitting'
  | 'bathroom-fitting'
  | 'flooring'
  | 'general-building'
  | 'painting-and-decorating'
  | 'glazing'
  | 'landscaping'

export const planningCategories: PlanningCategory[] = [
  'bathroom-fitting',
  'flooring',
  'general-building',
  'glazing',
  'kitchen-fitting',
  'landscaping',
  'painting-and-decorating',
  'tree-surgery',
]
