import { createSlice } from '@reduxjs/toolkit'
import { AuthFormUserResult } from '../../../models/AuthFormUserResult'
import actions from './actions'
export interface AuthState {
  user?: AuthFormUserResult
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: AuthState = {
  user: undefined,
  status: 'initial',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actions.tokenUpdated, (state, action) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload }
      }
    })
    builder.addCase(actions.loggedIn, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(actions.verified, state => {
      if (state.user) {
        state.user.emailVerified = true
      }
    })
    builder.addCase(actions.loggedOut, () => {
      return initialState
    })
  },
})

export default authSlice
