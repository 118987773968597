import { useState, useMemo, useContext, createContext } from 'react'
import { Dialog } from '@mui/material'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import DialogSlideRightToLeftTransition from '../../atoms/DialogSlideRightToLeftTransition'
import EditPostcodeDistrictsPanel from '../../molecules/EditPostcodeDistrictsPanel'

const PostcodeDistrictsDialogContext = createContext<{
  openPostcodeDistrictsDialog: () => void
  closePostcodeDistrictsDialog: () => void
} | null>(null)

const usePostcodeDistrictsDialog = () => {
  const context = useContext(PostcodeDistrictsDialogContext)
  if (!context) {
    throw new Error(
      'no PostcodeDistrictsDialogProvider found when calling usePostcodeDistrictsDialog()',
    )
  }
  return context
}

const PostcodeDistrictsDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openPostcodeDistrictsDialog = useCallbackDebounced(() => {
    setOpen(true)
  }, [])
  const closePostcodeDistrictsDialog = useCallbackDebounced(() => {
    setOpen(false)
  }, [])
  const value = useMemo(
    () => ({
      openPostcodeDistrictsDialog,
      closePostcodeDistrictsDialog,
    }),
    [closePostcodeDistrictsDialog, openPostcodeDistrictsDialog],
  )
  return (
    <PostcodeDistrictsDialogContext.Provider value={value}>
      {children}
      <Dialog
        open={open}
        TransitionComponent={DialogSlideRightToLeftTransition}
        onClose={closePostcodeDistrictsDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70vw',
            height: '100vh',
            width: 1000,
            position: 'fixed',
            top: 0,
            right: 0,
            margin: 0,
            maxHeight: 'none',
            borderRadius: 0,
          },
        }}
      >
        <EditPostcodeDistrictsPanel onSave={closePostcodeDistrictsDialog} />
      </Dialog>
    </PostcodeDistrictsDialogContext.Provider>
  )
}

export { usePostcodeDistrictsDialog, PostcodeDistrictsDialogProvider }
export default PostcodeDistrictsDialogProvider
