import { FC, memo } from 'react'
import { PlanningCategory } from '../../../models/PlanningCategory'
import Box from '@mui/material/Box'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'

const CategoryTile: FC<{
  planningCategory: PlanningCategory
  selected: boolean
  onChange: (planningCategory: PlanningCategory) => void
}> = ({ planningCategory, selected, onChange }) => {
  const onClick = useCallbackDebounced(() => {
    onChange(planningCategory)
  }, [planningCategory, onChange])
  return (
    <Box
      id={`category-tile-${planningCategory}`}
      key={planningCategory}
      component={'li'}
      aria-selected={selected}
      sx={{
        listStyle: 'none',
        padding: 2,
        border: '1px solid',
        borderColor: selected ? 'primary.main' : 'grey.300',
        borderRadius: 1,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
      onClick={onClick}
    >
      {planningCategory}
    </Box>
  )
}

export default memo(CategoryTile)
