import { useEffect, useCallback, useState } from 'react'
import TemplatePanel from './TemplatePanel'
import { useDispatch, useSelector, useSelectorWithRef } from '../../../redux/hooks'
import templateActions from '../../../redux/modules/template/actions'
import companyActions from '../../../redux/modules/company/actions'
import templateSelectors from '../../../redux/modules/template/selectors'
import authSelectors from '../../../redux/modules/auth/selectors'
import { Template } from '../../../models/Template'
import axios, { AxiosError } from 'axios'

const TemplatePanelBase = ({
  templateId,
  onSave,
  onClose,
  defaultInstantJSON,
}: {
  templateId?: string
  onSave: () => void
  onClose: () => void
  defaultInstantJSON?: any
}) => {
  const [loadingInstantJSONError, setLoadingInstantJSONError] = useState<string | undefined>()
  const [loadingInstantJSON, setLoadingInstantJSON] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [instantJSON, setInstantJSON] = useState<any | undefined>()
  const [afterCreate, setAfterCreate] = useState(false)
  const dispatch = useDispatch()
  const template = useSelector(templateSelectors.getTemplate)
  const [loading, loadingRef] = useSelectorWithRef(templateSelectors.getLoading)
  const companyId = useSelector(authSelectors.getCompanyId) as string
  const error = useSelector(templateSelectors.getError)
  useEffect(() => {
    if (
      companyId &&
      templateId &&
      !afterCreate &&
      !(template?.id === templateId && !loadingRef.current) // if the template is already loaded and there isnt a loading request pending (which would potentially make the template different in the store), we can skip reloading it
    ) {
      dispatch(templateActions.loadTemplate({ templateId, companyId }))
    }
  }, [companyId, dispatch, loadingRef, afterCreate, template?.id, templateId])
  const setTemplateAfterCreate = useCallback(
    (template: Template) => {
      setAfterCreate(true)
      dispatch(templateActions.setTemplateAfterCreate(template))
      dispatch(
        companyActions.updateLastChangedTemplate({
          lastChangedTemplateId: template.id,
          lastChangedTemplateVersion: template.version,
        }),
      )
    },
    [dispatch],
  )
  const updateTemplateAfterSave = useCallback(
    (version: number) => {
      dispatch(templateActions.updateTemplateAfterSave(version))
      dispatch(
        companyActions.updateLastChangedTemplate({
          lastChangedTemplateId: templateId as string,
          lastChangedTemplateVersion: version,
        }),
      )
    },
    [dispatch, templateId],
  )
  useEffect(() => {
    if (template?.id === templateId) {
      setLoadingInstantJSON(true)
      axios
        .get(template?.instantJSONUrl as string)
        .then(response => {
          setInstantJSON(response.data)
          setLoadingInstantJSONError(undefined)
          setLoadingInstantJSON(false)
        })
        .catch((error: AxiosError) => {
          setLoadingInstantJSONError(error.message)
          setLoadingInstantJSON(false)
        })
    }
  }, [template?.id, template?.instantJSONUrl, templateId])
  return (
    <TemplatePanel
      mode={templateId ? 'edit' : 'create'}
      loading={(loading || loadingInstantJSON) && !!templateId}
      loadingError={error || loadingInstantJSONError}
      companyId={companyId}
      template={templateId && template?.id === templateId && !!instantJSON ? template : undefined}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      instantJSON={instantJSON}
      setTemplateAfterCreate={setTemplateAfterCreate}
      updateTemplateAfterSave={updateTemplateAfterSave}
      afterCreate={!!afterCreate}
      onClose={onClose}
      onSave={onSave}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      defaultInstantJSON={defaultInstantJSON}
    />
  )
}

export default TemplatePanelBase
