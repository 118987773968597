import { FC, memo } from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { Controller } from 'react-hook-form'
import monthlyCreditLimitForm from '../../../forms/monthlyCreditLimitForm'
import { TextField } from '@mui/material'
import { useSubscription } from '../../providers/SubscriptionProvider'

const SubscriptionPanel: FC = () => {
  const {
    credits,
    pendingCredits,
    onHandleSubmitSubscribe,
    onHandleSubmitUnsubscribe,
    onHandleSubmitTopup,
    monthlyCreditLimitControl,
    additionalTopupAmount,
    state,
    subscribeDisabled,
    unsubscribeDisabled,
    topupDisabled,
    monthlyCreditLimitDisabled,
    topupError,
    subscribeError,
    unsubscribeError,
    monthlyCreditLimitError,
    monthlyCreditLimitSubmitting,
    topupSubmitting,
    subscribeSubmitting,
    unsubscribeSubmitting,
  } = useSubscription()
  const error = topupError || subscribeError || unsubscribeError || monthlyCreditLimitError
  if (state === 'LOADING') {
    return <div id="subscribtion-panel-loader">loading</div>
  }
  if (state === 'PROCESSING_PAYMENT') {
    return <div id="processing-payment-loader">processing payment</div>
  }
  if (state === 'PROCESSING_SUBSCRIPTION') {
    // same as processing payment but only triggers when a user unsubscribes and resubscribes whilst already being topped up to full, or changing card details (which doesnt exist yet)
    return <div id="processing-subscription-loader">processing subscription</div>
  }
  if (state === 'PAYMENT_COMPLETE') {
    return (
      <div id="processing-payment-complete">
        successfully set up subscription and purchased credits
      </div>
    )
  }
  if (state === 'SUBSCRIPTION_COMPLETE') {
    return <div id="processing-subscription-complete">successfully set up subscription</div>
  }
  return (
    <div id="subscription-panel">
      <Controller
        name="monthlyCreditLimit"
        control={monthlyCreditLimitControl}
        render={({ field, fieldState }) => (
          <>
            <TextField
              id="monthly-credit-limit-input"
              {...field}
              value={field.value == null ? '' : field.value}
              onChange={event => {
                field.onChange(parseInt(event.target.value))
              }}
              type="number"
              label="Monthly credit limit"
              InputProps={{
                error: !!fieldState.error?.message,
              }}
              disabled={monthlyCreditLimitDisabled}
            />
            <div>{field.value && `£${field.value * 3} per month`}</div>
            <div>{fieldState.error?.message}</div>
          </>
        )}
        rules={{
          validate: monthlyCreditLimitForm.validateMonthlyCreditLimitField,
        }}
      />
      {monthlyCreditLimitSubmitting && <div>submitting</div>}
      {monthlyCreditLimitError && <div>{monthlyCreditLimitError}</div>}
      {state === 'SUBSCRIBED' && (
        <>
          {topupSubmitting && <div>submitting</div>}
          {
            <div>
              credits {credits || 0}{' '}
              {pendingCredits !== credits ? `pendingCredits ${pendingCredits || 0}` : ``}
            </div>
          }
          {additionalTopupAmount > 0 && (
            <div>
              Would you like to purchase an additional {additionalTopupAmount} credits for £
              {additionalTopupAmount * 3} to bring your monthly limits in line with your purchased
              credits?
              <button onClick={onHandleSubmitTopup} disabled={topupDisabled}>
                purchase
              </button>
            </div>
          )}
          <button onClick={onHandleSubmitUnsubscribe} disabled={unsubscribeDisabled}>
            cancel subscription
          </button>
          {unsubscribeSubmitting && <div>submitting</div>}
          {error && <div>Error: {error}</div>}
        </>
      )}
      {state === 'UNSUBSCRIBED' && (
        <>
          <PaymentElement
            options={{
              readOnly: subscribeSubmitting,
            }}
          />
          {/* {JSON.stringify(formStateMonthlyCreditLimit.isSubmitting, null, 2)} */}
          <button
            id="subscription-subscribe"
            onClick={onHandleSubmitSubscribe}
            disabled={subscribeDisabled}
          >
            subscribe
          </button>
          {subscribeSubmitting && <div>submitting</div>}
          <p>4000 0025 0000 3155 SCA with push notification etc</p>
          <p>4000 0000 0000 3063 3DS</p>
          <p>4242 4242 4242 4242 success</p>
          <p>4000 0000 0000 0341 fail</p>
          {error && <div>Error: {error}</div>}
        </>
      )}
    </div>
  )
}

export default memo(SubscriptionPanel)
