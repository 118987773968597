import { FC, memo, ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import TopNavBar from '../TopNavBar'

const PageTemplate: FC<{
  sidebarElement?: ReactElement
}> = ({ sidebarElement }) => {
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <TopNavBar />
      </Box>
      <Box sx={{ display: 'flex' }}>
        {sidebarElement && (
          <Box
            component="nav"
            sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
            aria-label="navigation"
          >
            <Box
              sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 240,
                  boxSizing: 'border-box',
                },
              }}
            >
              {sidebarElement}
            </Box>
          </Box>
        )}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(PageTemplate)
