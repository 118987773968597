import { useNavigate } from 'react-router-dom'
import TopNavBar from './TopNavBar'
import { useDispatch, useSelector } from '../../../redux/hooks'
import authActions from '../../../redux/modules/auth/actions'
import authSelectors from '../../../redux/modules/auth/selectors'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import { FC } from 'react'

const TopNavBarBase: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(authSelectors.getLoggedIn)
  const email = useSelector(authSelectors.getEmail)
  const login = useCallbackDebounced(async () => {
    navigate('/login')
  }, [navigate])
  const logout = useCallbackDebounced(async () => {
    dispatch(authActions.loggedOut())
    navigate('/login')
  }, [dispatch, navigate])
  return <TopNavBar login={login} logout={logout} email={email || ''} isLoggedIn={isLoggedIn} />
}

export default TopNavBarBase
