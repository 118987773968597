class Validators {
  combineValidators = (
    ...validators: ((value: any, values: any) => string | undefined | Promise<string | undefined>)[]
  ): ((value: any, values: any) => Promise<string | undefined>) => {
    return async (value: any, values: any): Promise<string | undefined> => {
      let error
      for (const validator of validators) {
        const result = validator(value, values)
        if (result) {
          // @ts-expect-error .then might not exist on string or undefined and its fine because thats what this is checking
          if (result.then) {
            const promiseResult = await result
            if (promiseResult) {
              error = result
              break
            }
          } else {
            error = result
            break
          }
        }
      }
      return error
    }
  }

  validateLengthMin =
    ({
      min,
      errorMessage = `The value must be at least ${min} characters long.`,
    }: {
      min: number
      errorMessage: string
    }): ((value: string) => string | undefined) =>
    (value: string) => {
      if (value && value.length < min) {
        return errorMessage
      }
    }

  validateNumber =
    ({
      errorMessage = `The value must be a number.`,
    }: {
      errorMessage: string
    }): ((value: number) => string | undefined) =>
    (value: number) => {
      if (!Number.isInteger(value) || Number(value) < 0) {
        return errorMessage
      }
    }

  validateOptionsMulti =
    <T>({
      errorMessage = `The value must be a number.`,
      options,
    }: {
      errorMessage: string
      options: T[]
    }): ((value: T[]) => string | undefined) =>
    (value: T[]) => {
      if (!options.some(option => value.includes(option))) {
        return errorMessage
      }
    }

  validateOneOf =
    <T>({
      errorMessage = `The value must be a number.`,
      options,
    }: {
      errorMessage: string
      options: T[]
    }): ((value: T) => string | undefined) =>
    (value: T) => {
      if (!options.includes(value)) {
        return errorMessage
      }
    }

  validateLengthMax =
    ({
      max,
      errorMessage = `The value can be a maximum of ${max} characters long.`,
    }: {
      max: number
      errorMessage: string
    }): ((value: string) => string | undefined) =>
    (value: string) => {
      if (value && value.length > max) {
        return errorMessage
      }
    }

  validateRequired =
    ({
      errorMessage = 'The value cannot be empty.',
    }: {
      errorMessage: string
    }): ((value: any) => string | undefined) =>
    (value: any) => {
      if (
        !value ||
        (typeof value === 'string' && !value.trim()) ||
        (Array.isArray(value) && value.length === 0)
      ) {
        return errorMessage
      }
    }

  validateEmail =
    ({
      errorMessage = 'The value is not a valid email.',
    }: {
      errorMessage: string
    }): ((value: string) => string | undefined) =>
    (value: string) => {
      if (!value) {
        return
      }
      if (!value.includes('@')) {
        return errorMessage
      }
      const emailDomain = value.replace(/.*@/, '')
      const validLookingEmailDomainRegex = /\w+\.\w/ // at least one letter, followed with a dot and one letters
      // N.B.: not necessarily right after @ sign, and not neccesarily at the end of a string, e.g. "atx.com.uk" matches
      if (!emailDomain || !validLookingEmailDomainRegex.test(emailDomain)) {
        return errorMessage
      }
    }
  validatePostcode =
    ({
      errorMessage = 'The value is not a valid postcode.',
    }: {
      errorMessage: string
    }): ((value: string) => string | undefined) =>
    (value: string) => {
      if (!value) {
        return
      }
      if (value.match(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i) === null) {
        return errorMessage
      }
    }

  // validateUnique =
  //   ({
  //     reducer = (memo, next) => {
  //       return { ...memo, [next]: true }
  //     },
  //     accumulator = {},
  //     errorMessage = '',
  //   } = {}) =>
  //   value => {
  //     const dictionary = value.reduce(reducer, accumulator)
  //     if (Object.keys(dictionary).length !== value.length) {
  //       return errorMessage
  //     }
  //   }
}

export default new Validators()
