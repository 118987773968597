import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../types/RootState'
import config from '../../../config'

class TemplateSelectors {
  getCompany = (state: RootState) => state.company.company
  getLoading = (state: RootState) =>
    state.company.status === 'initial' || state.company.status === 'loading'
  getHasError = (state: RootState) => state.company.status === 'failed'
  getError = (state: RootState) => state.company.error
  getSubscriptionAwaitingSync = (state: RootState) => state.company.subscriptionAwaitingSync
  getPaymentAwaitingSync = (state: RootState) => state.company.paymentAwaitingSync
  getHasActiveSubscription = createSelector([this.getCompany], company => {
    return !!(company?.stripeCustomerId && company?.stripePaymentMethodId)
  })
  getHasHadSubscription = createSelector([this.getCompany], company => {
    return !!company?.creditsPurchasedYearMonth
  })
  getCompanyCreditsPurchased = createSelector([this.getCompany], company => {
    return company?.creditsPurchased !== undefined ? company?.creditsPurchased : null
  })
  getCompanyMonthlyCreditLimit = createSelector([this.getCompany], company => {
    return company?.monthlyCreditLimit !== undefined ? company?.monthlyCreditLimit : null
  })
  getCompanyCredits = createSelector([this.getCompany], company => {
    return company?.credits !== undefined ? company?.credits : null
  })
  getCompanyPendingCredits = createSelector(
    [
      this.getCompanyMonthlyCreditLimit,
      this.getCompanyCreditsPurchased,
      this.getPaymentAwaitingSync,
      this.getCompanyCredits,
    ],
    (monthlyCreditLimit, creditsPurchased, paymentAwaitingSync, credits) => {
      if (paymentAwaitingSync && monthlyCreditLimit !== null && creditsPurchased !== null) {
        const difference = monthlyCreditLimit - creditsPurchased
        const creditsAfterTopup = (credits || 0) + difference
        return creditsAfterTopup
      }
      return credits
    },
  )

  getCompanyId = createSelector([this.getCompany], company => {
    return company?.id || null
  })
  getCompanyPlanningCategories = createSelector([this.getCompany], company => {
    return company?.planningCategories || null
  })
  getLastChangedTemplateId = createSelector([this.getCompany], company => {
    return company?.lastChangedTemplateId
  })
  getLastChangedTemplateVersion = createSelector([this.getCompany], company => {
    return company?.lastChangedTemplateVersion !== undefined
      ? company?.lastChangedTemplateVersion
      : null
  })
  getCompanyHasTemplate = createSelector([this.getLastChangedTemplateId], lastChangedTemplateId => {
    return !!lastChangedTemplateId
  })
  getCompanyPostcodeDistricts = createSelector([this.getCompany], company => {
    return company?.postcodeDistricts || null
  })
  getPendingReviewTemplateId = createSelector([this.getCompany], company => {
    return company?.pendingReviewTemplateId || null
  })
  getPendingReviewTemplateVersion = createSelector([this.getCompany], company => {
    return company?.pendingReviewTemplateVersion !== undefined
      ? company?.pendingReviewTemplateVersion
      : null
  })
  getPendingReviewTemplateFeedback = createSelector([this.getCompany], company => {
    return company?.pendingReviewTemplateFeedback || null
  })
  getCompanyHasPendingReviewTemplate = createSelector(
    [this.getPendingReviewTemplateId],
    pendingReviewTemplateId => {
      return !!pendingReviewTemplateId
    },
  )
  getDefaultTemplateId = createSelector([this.getCompany], company => {
    return company?.defaultTemplateId || null
  })
  getDefaultTemplateVersion = createSelector([this.getCompany], company => {
    return company?.defaultTemplateVersion !== undefined ? company?.defaultTemplateVersion : null
  })
  getCompanyHasDefaultTemplate = createSelector([this.getDefaultTemplateId], defaultTemplateId => {
    return !!defaultTemplateId
  })
  getPendingReviewTemplateImageUrl = createSelector(
    [this.getCompanyId, this.getPendingReviewTemplateId, this.getPendingReviewTemplateVersion],
    (companyId, pendingReviewTemplateId, pendingReviewTemplateVersion) => {
      if (pendingReviewTemplateId === null || pendingReviewTemplateVersion === null) {
        return null
      }
      return `${config.imgixBaseUrl}templates/${companyId}/${pendingReviewTemplateId}_${pendingReviewTemplateVersion}.pdf?page=1&fm=png&auto=format&fit=max&w=620&h=465`
    },
  )
  getDefaultTemplateImageUrl = createSelector(
    [this.getCompanyId, this.getDefaultTemplateId, this.getDefaultTemplateVersion],
    (companyId, defaultTemplateId, defaultTemplateVersion) => {
      if (defaultTemplateId === null || defaultTemplateVersion === null) {
        return null
      }
      return `${config.imgixBaseUrl}templates/${companyId}/${defaultTemplateId}_${defaultTemplateVersion}.pdf?page=1&fm=png&auto=format&fit=max&w=620&h=465`
    },
  )
  getNextMissingOnboarding = createSelector(
    [
      this.getCompanyPostcodeDistricts,
      this.getCompanyPlanningCategories,
      this.getCompanyHasTemplate,
      this.getHasActiveSubscription,
    ],
    (
      companyPostcodeDistricts,
      companyPlanningCategories,
      hasTemplate,
      hasActiveSubscription,
    ): 'coverage' | 'planning-categories' | 'template' | 'subscribe' | null => {
      if (hasActiveSubscription) {
        return null
      }
      if (companyPostcodeDistricts?.length && companyPlanningCategories?.length) {
        return null
      }
      if (hasTemplate) {
        return null
      }
      if (!companyPostcodeDistricts?.length) {
        return 'coverage'
      }
      if (!companyPlanningCategories?.length) {
        return 'planning-categories'
      }
      if (!hasTemplate) {
        return 'template'
      }
      return 'subscribe'
    },
  )
}

export default new TemplateSelectors()
