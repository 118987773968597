import { FC, memo } from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'

const TopNavBar: FC<{
  login: () => void
  logout: () => void
  email: string
  isLoggedIn: boolean
}> = ({ login, logout, email, isLoggedIn }) => {
  return (
    <AppBar position="static" variant="outlined" elevation={0}>
      <Toolbar variant="dense">
        {email}
        <Box sx={{ flex: 1 }} />
        <Button color="inherit" onClick={isLoggedIn ? logout : login}>
          {isLoggedIn ? 'Logout' : 'Login'}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default memo(TopNavBar)
