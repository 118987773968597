import { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import planningCategoriesForm from '../../../forms/planningCategoriesForm'
import { PlanningCategory } from '../../../models/PlanningCategory'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import CategoryGrid from '../../atoms/CategoryGrid'

const defaultPlanningCategories = []

const EditPlanningCategoriesPanel: FC<{
  onSave?: () => void
  loading: boolean
  companyId: string | null
  planningCategories: PlanningCategory[] | null
}> = ({ onSave, loading, companyId, planningCategories }) => {
  const { control, handleSubmit, formState, reset, setError, watch } = useForm<{
    planningCategories: PlanningCategory[]
  }>({
    mode: 'onTouched',
    defaultValues: {
      planningCategories: planningCategories || defaultPlanningCategories,
    },
  })
  const formPlanningCategories = watch('planningCategories')
  useEffect(() => {
    reset(
      {
        planningCategories: planningCategories || defaultPlanningCategories,
      },
      { keepDirtyValues: true },
    )
  }, [planningCategories, reset])
  const onSubmit: SubmitHandler<{
    planningCategories: PlanningCategory[]
  }> = useCallbackDebounced(
    ({ planningCategories }: { planningCategories: PlanningCategory[] }) => {
      if (companyId == null) {
        return
      }
      return planningCategoriesForm
        .onSubmit({
          companyId,
          planningCategories,
        })
        .then(() => {
          reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false })
          onSave && onSave()
        })
        .catch((error: Error) => {
          setError('root', { message: error.message })
        })
    },
    [companyId, reset, setError, onSave],
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="planningCategories"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <CategoryGrid planningCategories={field.value} onChange={field.onChange} />
              <div>{fieldState.error?.message}</div>
            </>
          )}
          rules={{
            validate: planningCategoriesForm.validatePlanningCategoriesField,
          }}
        />
        <button type="submit" disabled={formState.isSubmitting || !formPlanningCategories.length}>
          save
        </button>
        {formState.errors.root?.message && <div>{formState.errors.root.message}</div>}
      </form>
      {`loading ${loading} `}
    </>
  )
}

export default EditPlanningCategoriesPanel
