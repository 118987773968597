import { FC, useEffect, useState } from 'react'
import { Box, Dialog } from '@mui/material'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'
import TemplatePanel from '../TemplatePanel'
import PSPDFKit from 'pspdfkit'

const templateJson1 = {
  annotations: [
    {
      bbox: [24.485748291015625, 294.95318603515625, 557.971923828125, 64.99996948242188],
      borderWidth: 0,
      createdAt: '2024-08-02T20:42:13Z',
      font: 'Helvetica',
      fontColor: '#000000',
      fontSize: 18,
      horizontalAlign: 'left',
      id: '01J4AD89JG84B7C299F41KQYQS',
      isFitting: true,
      lineHeightFactor: 1.186000108718872,
      name: '01J4AD89JG84B7C299F41KQYQS',
      opacity: 1,
      pageIndex: 0,
      rotation: 0,
      text: {
        format: 'plain',
        value:
          'Hello {{applicant}}, congratulations on having your planning permission approved at {{address}}. I operate in the {{postcode}} postcode and wanted to offer my services. PENISES!',
      },
      type: 'pspdfkit/text',
      updatedAt: '2024-08-30T22:48:32Z',
      v: 2,
      verticalAlign: 'top',
    },
  ],
  format: 'https://pspdfkit.com/instant-json/v1',
}

const CreateTemplatePanel: FC<{
  onSave?: () => void
  onClose?: () => void
  onOpen?: () => void
}> = ({ onSave, onClose, onOpen }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [defaultInstantJSON, setDefaultInstantJSON] = useState<any>()
  const [templateOpen, setTemplateOpen] = useState(false)
  useEffect(() => {
    // @ts-expect-error container not required
    PSPDFKit.preloadWorker({
      document: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/blank.pdf`,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
    })
  }, [])
  const handleCloseTemplateModal = useCallbackDebounced(() => {
    setTemplateOpen(false)
    onClose && onClose()
    setDefaultInstantJSON(undefined)
  }, [onClose])

  const handleSaveTemplate = useCallbackDebounced(() => {
    setTemplateOpen(false)
    onSave && onSave()
    setDefaultInstantJSON(undefined)
  }, [onSave])

  return (
    <Box>
      <button
        onClick={() => {
          setDefaultInstantJSON(templateJson1)
          setTemplateOpen(true)
          onOpen && onOpen()
        }}
      >
        template json 1
      </button>
      <Dialog fullScreen open={templateOpen} onClose={handleCloseTemplateModal}>
        <TemplatePanel
          onClose={handleCloseTemplateModal}
          onSave={handleSaveTemplate}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultInstantJSON={defaultInstantJSON}
        />
      </Dialog>
    </Box>
  )
}

export default CreateTemplatePanel
