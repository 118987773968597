import { createSlice } from '@reduxjs/toolkit'
import { PlanningRecord } from '../../../models/PlanningRecord'
import authActions from '../auth/actions'
import actions from './actions'

export interface PlanningRecordsState {
  planningRecords: PlanningRecord[]
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
}

const initialState: PlanningRecordsState = {
  planningRecords: [],
  status: 'initial',
}

export const planningRecordsSlice = createSlice({
  name: 'planningRecords',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.loadPlanningRecordsFresh.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadPlanningRecordsFresh.fulfilled, (state, action) => {
        state.status = 'idle'
        state.planningRecords = action.payload.data
        state.error = undefined
      })
      .addCase(actions.loadPlanningRecordsFresh.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.loadPlanningRecordsNext.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.loadPlanningRecordsNext.fulfilled, (state, action) => {
        state.status = 'idle'
        state.planningRecords.push(...action.payload.data)
        state.error = undefined
      })
      .addCase(actions.loadPlanningRecordsNext.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(actions.updatePlanningRecord, (state, action) => {
        for (let i = 0, length = state.planningRecords.length; i < length; i++) {
          const planningRecord = state.planningRecords[i]
          if (planningRecord.id === action.payload.id) {
            state.planningRecords[i] = action.payload
            break
          }
        }
      })
      .addCase(actions.approvePlanningRecord, (state, action) => {
        for (let i = 0, length = state.planningRecords.length; i < length; i++) {
          const planningRecord = state.planningRecords[i]
          if (planningRecord.id === action.payload.id) {
            state.planningRecords.splice(i, 1)
            break
          }
        }
      })
      .addCase(actions.discardPlanningRecord, (state, action) => {
        for (let i = 0, length = state.planningRecords.length; i < length; i++) {
          const planningRecord = state.planningRecords[i]
          if (planningRecord.id === action.payload.id) {
            state.planningRecords.splice(i, 1)
            break
          }
        }
      })
  },
})

export default planningRecordsSlice
