import { FC, memo } from 'react'
import { useSelector } from '../../../redux/hooks'
import authSelectors from '../../../redux/modules/auth/selectors'
import { useShowOnboarding } from '../../providers/OnboardingProvider'
import PageTemplate from '../../molecules/PageTemplate'
import Sidebar from '../Sidebar/Sidebar'

const PageLayout: FC = () => {
  const isLoggedIn = useSelector(authSelectors.getLoggedIn)
  const role = useSelector(authSelectors.getRole)
  const { loading: loadingOnboarding, showOnboarding } = useShowOnboarding()
  const showSidebar =
    location.pathname === '/' &&
    !loadingOnboarding &&
    !showOnboarding &&
    isLoggedIn &&
    role !== 'super-admin'
  return <PageTemplate sidebarElement={showSidebar ? <Sidebar /> : undefined} />
}

export default memo(PageLayout)
