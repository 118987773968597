import { SetupIntent } from '@stripe/stripe-js'
import { getFunctions, httpsCallable } from 'firebase/functions'

class StripeService {
  subscribe = async ({ paymentMethodId }: { paymentMethodId: string }) => {
    const subscribe = httpsCallable<
      { paymentMethodId: string },
      { status: SetupIntent.Status; clientSecret: string }
    >(getFunctions(undefined, 'europe-west2'), 'subscribe')
    const data = await subscribe({ paymentMethodId }).then(({ data }) => data)
    return data
  }

  unsubscribe = async () => {
    const unsubscribe = httpsCallable<null, { success: boolean }>(
      getFunctions(undefined, 'europe-west2'),
      'unsubscribe',
    )
    await unsubscribe()
  }

  topup = async () => {
    const topup = httpsCallable<null, { success: boolean }>(
      getFunctions(undefined, 'europe-west2'),
      'topup',
    )
    await topup()
  }
}

export default new StripeService()
