import { FC, memo, useMemo } from 'react'
import {
  PlanningCategory,
  planningCategories as allPlanningCategories,
} from '../../../models/PlanningCategory'
import Box from '@mui/material/Box'
import useStableCallback from '../../../hooks/shared/useStableCallback'
import CategoryTile from './CategoryTile'

const CategoryGrid: FC<{
  planningCategories: PlanningCategory[]
  onChange: (planningCategories: PlanningCategory[]) => void
}> = ({ planningCategories, onChange }) => {
  const selectedPlanningCategories = useMemo(() => {
    return planningCategories.reduce(
      (memo, next) => {
        memo[next] = true
        return memo
      },
      {} as Record<PlanningCategory, boolean>,
    )
  }, [planningCategories])
  const onChangeCategory = useStableCallback((planningCategory: PlanningCategory) => {
    if (planningCategories.includes(planningCategory)) {
      const indexToRemove = planningCategories.indexOf(planningCategory)
      onChange([
        ...planningCategories.slice(0, indexToRemove),
        ...planningCategories.slice(indexToRemove + 1),
      ])
    } else {
      onChange([...planningCategories, planningCategory])
    }
  })
  return (
    <Box component={'ul'} sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
      {allPlanningCategories.map(planningCategory => {
        return (
          <CategoryTile
            key={planningCategory}
            planningCategory={planningCategory}
            onChange={onChangeCategory}
            selected={selectedPlanningCategories[planningCategory]}
          />
        )
      })}
    </Box>
  )
}

export default memo(CategoryGrid)
