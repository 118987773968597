import stripeService from '../../services/stripeService'
import { Stripe, StripeElements } from '@stripe/stripe-js'

class SubscribeForm {
  onSubmit = async ({
    elements,
    stripe,
  }: {
    elements: StripeElements
    stripe: Stripe
  }): Promise<void> => {
    const { error: submitError } = await elements.submit()
    if (submitError) {
      throw submitError
    }
    const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    })
    if (createPaymentMethodError) {
      throw createPaymentMethodError
    }
    const { status, clientSecret } = await stripeService.subscribe({
      paymentMethodId: paymentMethod.id,
    })
    if (status === 'requires_action') {
      // Use Stripe.js to handle the required next action
      const { error: handleNextActionError } = await stripe.handleNextAction({
        clientSecret: clientSecret,
      })
      if (handleNextActionError) {
        throw handleNextActionError
      }
    }
    if (
      status === 'canceled' ||
      status === 'requires_payment_method' ||
      status === 'requires_confirmation' ||
      status === 'processing'
    ) {
      throw new Error('Something may have gone wrong. Please refresh the page.')
    }
  }
}

export default new SubscribeForm()
