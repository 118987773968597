import { createSlice } from '@reduxjs/toolkit'
import { Template } from '../../../models/Template'
import authActions from '../auth/actions'
import actions from './actions'

export interface TemplatesState {
  templates: Template[]
  error?: string
  status: 'initial' | 'idle' | 'loading' | 'failed'
  currentRequest?: string
}

const initialState: TemplatesState = {
  templates: [],
  status: 'initial',
}

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.updateTemplate, (state, action) => {
        for (let i = 0, length = state.templates.length; i < length; i++) {
          const template = state.templates[i]
          if (template.id === action.payload.id) {
            state.templates[i] = action.payload
          }
        }
      })
      .addCase(actions.addTemplate, (state, action) => {
        const templateIsAlreadyListed =
          state.templates.findIndex(template => template.id === action.payload.id) >= 0
        if (!templateIsAlreadyListed) {
          state.templates.push(action.payload)
        }
      })
      .addCase(actions.loadTemplates.pending, (state, action) => {
        state.currentRequest = action.meta.requestId
        state.status = 'loading'
      })
      .addCase(actions.loadTemplates.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.templates = action.payload.data
          state.error = undefined
        } else {
          action.payload.unsubscribe()
        }
      })
      .addCase(actions.loadTemplates.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
  },
})

export default templatesSlice
