import { useEffect } from 'react'
import { UseFormWatch } from 'react-hook-form'

export const useAutoSave = ({ watch, submit }: { watch: UseFormWatch<any>; submit: () => any }) => {
  useEffect(() => {
    const subscription = watch((_value, { type }) => {
      if (type === 'change') {
        submit()
      }
    })
    return () => subscription.unsubscribe()
  }, [submit, watch])
}
