import maplibregl from 'maplibre-gl'
import { memo, useEffect, useRef } from 'react'
import { useStateWithRef } from '../../../hooks/shared/useStateWithRef'
import { PostcodeDistrict } from '../../../models/PostcodeDistrict'
import _ from 'lodash'
import { useQuery } from '../../../hooks/shared/useQuery'
import geojsonService from '../../../services/geojsonService'

const DistrictMap = ({
  postcodeDistricts,
  onChange,
}: {
  postcodeDistricts: PostcodeDistrict[]
  onChange: (postcodeDistricts: PostcodeDistrict[]) => void
}) => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const currentHoveredDistrictRef = useRef<string>()
  const selectedFeatureIds = useRef<number[]>([])
  const postcodeDistrictsRef = useRef<PostcodeDistrict[]>([])
  const [map, setMap, mapRef] = useStateWithRef<maplibregl.Map | null>(null)
  const { data, loading, error } = useQuery(geojsonService.fetchGeojson)
  useEffect(() => {
    if (map && data) {
      const postcodeDistrictsAdded = _.difference(postcodeDistricts, postcodeDistrictsRef.current)
      const postcodeDistrictsRemoved = _.difference(postcodeDistrictsRef.current, postcodeDistricts)
      postcodeDistrictsRef.current = postcodeDistricts
      for (const postcodeDistrict of postcodeDistrictsAdded) {
        const featureId = generateNumericId(postcodeDistrict)
        if (!selectedFeatureIds.current.includes(featureId)) {
          selectedFeatureIds.current.push(featureId)
          map.setFeatureState({ source: 'districts', id: featureId }, { selected: true })
        }
      }
      for (const postcodeDistrict of postcodeDistrictsRemoved) {
        const featureId = generateNumericId(postcodeDistrict)
        if (selectedFeatureIds.current.includes(featureId)) {
          selectedFeatureIds.current.splice(selectedFeatureIds.current.indexOf(featureId), 1)
          map.removeFeatureState({ source: 'districts', id: featureId })
        }
      }
    }
  }, [data, map, postcodeDistricts])
  useEffect(() => {
    if (!data) return // stops map from intializing more than once
    if (mapRef.current) return // stops map from intializing more than once
    if (!mapContainer.current) return
    const mapInstance = new maplibregl.Map({
      container: mapContainer.current,
      attributionControl: false,
      style: {
        version: 8,
        sources: {
          districts: {
            type: 'geojson',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: data.districts,
          },
          gb: {
            type: 'geojson',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: data.gb,
          },
          places: {
            type: 'geojson',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: data.places,
          },
          roads: {
            type: 'geojson',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: data.roads,
          },
        },
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf', // Adding the glyphs property
        layers: [
          {
            id: 'gb-fill',
            type: 'fill',
            source: 'gb',
            layout: {},
            paint: {
              'fill-color': '#e9f2e7', // Green color
            },
          },
          {
            id: 'motorways',
            type: 'line',
            source: 'roads',
            minzoom: 6, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'motorway'],
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#fff',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                6,
                0.3, // At zoom level 6, line width will be 0.3
                7,
                2, // At zoom level 7+, line width will be 2
              ],
            },
          },
          {
            id: 'trunks',
            type: 'line',
            source: 'roads',
            minzoom: 7, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'trunk'],
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#fff',
              'line-width': 1,
            },
          },
          {
            id: 'primary',
            type: 'line',
            source: 'roads',
            minzoom: 8, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'primary'],
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#fff',
              'line-width': 1,
            },
          },
          // {
          //   id: 'secondary',
          //   type: 'line',
          //   source: 'roads',
          //   minzoom: 9, // Only show when zoom level is 6 and higher
          //   maxzoom: 22, // Show up to the highest zoom level
          //   filter: ['==', ['get', 'type'], 'secondary'],
          //   layout: {
          //     'line-join': 'round',
          //     'line-cap': 'round',
          //   },
          //   paint: {
          //     'line-color': 'orange',
          //     'line-width': 1,
          //   },
          // },
          {
            id: 'villages-labels',
            type: 'symbol',
            source: 'places',
            minzoom: 12, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'village'],
            layout: {
              'text-field': ['get', 'name'], // Get the 'name' property from GeoJSON
              // 'text-font': ['Roboto Regular'],
              'text-size': 10, // Font size
              'text-anchor': 'center', // Position text at the center
              'text-justify': 'center',
              'symbol-sort-key': 1, // lowest priority
              'text-optional': true, // doesnt have to be rendered if no space
            },
            paint: {
              'text-color': '#b3aeae', // Text color
            },
          },
          {
            id: 'towns-labels',
            type: 'symbol',
            source: 'places',
            minzoom: 9, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'town'],
            layout: {
              'text-field': ['get', 'name'], // Get the 'name' property from GeoJSON
              // 'text-font': ['Roboto Regular'],
              'text-size': 10, // Font size
              'text-anchor': 'center', // Position text at the center
              'text-justify': 'center',
              'symbol-sort-key': 1, // lowest priority
              'text-optional': true, // doesnt have to be rendered if no space
            },
            paint: {
              'text-color': '#b3aeae', // Text color
            },
          },
          {
            id: 'cities-labels',
            type: 'symbol',
            source: 'places',
            minzoom: 7, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'city'],
            layout: {
              'text-field': ['get', 'name'], // Get the 'name' property from GeoJSON
              // 'text-font': ['Roboto Regular'],
              'text-size': 12, // Font size
              'text-anchor': 'center', // Position text at the center
              'text-justify': 'center',
              'symbol-sort-key': 2, // medium priority
              'text-optional': true, // doesnt have to be rendered if no space
            },
            paint: {
              'text-color': '#b3aeae', // Text color
            },
          },
          {
            id: 'major-cities-labels',
            type: 'symbol',
            source: 'places',
            minzoom: 6, // Only show when zoom level is 6 and higher
            maxzoom: 22, // Show up to the highest zoom level
            filter: ['==', ['get', 'type'], 'major-city'],
            layout: {
              'text-field': ['get', 'name'], // Get the 'name' property from GeoJSON
              // 'text-font': ['Roboto Regular'],
              'text-size': 14, // Font size
              'text-anchor': 'center', // Position text at the center
              'text-justify': 'center',
              'symbol-sort-key': 3, // medium priority
              'text-optional': true, // doesnt have to be rendered if no space
            },
            paint: {
              'text-color': '#b3aeae', // Text color
            },
          },
          {
            id: 'districts-fill',
            type: 'fill',
            source: 'districts',
            layout: {},
            paint: {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                '#008000', // Color when selected
                '#d9ded6', // Default color
              ],
              'fill-opacity': 0.3, // Adjust opacity as needed
            },
          },
          {
            id: 'districts-border',
            type: 'line',
            source: 'districts',
            layout: {},
            paint: {
              // 'line-color': '#008000', // Black color for the outline
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                '#769e75', // Color when selected
                '#b3aeae', // Default color
              ],
              // 'line-width': 0.8,
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                6,
                0.1, // At zoom level 6, line width will be 0.3
                7,
                0.8, // At zoom level 7+, line width will be 2
              ],
            },
          },
        ],
      },
      zoom: 8,
      center: [-3.78, 50.83], // zoom in on Devon
      minZoom: 4, // Minimum zoom level
      maxZoom: 12, // Maximum zoom level
      maxBounds: [
        [-10.5, 49.5], // Southwest coordinates [longitude, latitude]
        [2.1, 61.0], // Northeast coordinates [longitude, latitude]
      ],
    })
    setMap(mapInstance)
    // @ts-expect-error districtMap is a global variable
    window.districtMap = mapInstance
    mapInstance.on(
      'click',
      'districts-fill',
      (
        e: maplibregl.MapMouseEvent & {
          features?: maplibregl.MapGeoJSONFeature[] | undefined
        },
      ) => {
        console.log(`Current lng: ${e.lngLat.lng} lat: ${e.lngLat.lat}`)
        const selectedFeature = e.features?.[0]
        if (!selectedFeature) {
          return
        }
        if (selectedFeature.state.selected) {
          selectedFeatureIds.current.splice(
            selectedFeatureIds.current.indexOf(Number(selectedFeature.id)),
            1,
          )
          const indexToRemove = postcodeDistrictsRef.current.indexOf(
            selectedFeature.properties.name as PostcodeDistrict,
          )
          postcodeDistrictsRef.current = [
            ...postcodeDistrictsRef.current.slice(0, indexToRemove),
            ...postcodeDistrictsRef.current.slice(indexToRemove + 1),
          ]
          onChange(postcodeDistrictsRef.current)
          mapInstance.removeFeatureState({ source: 'districts', id: selectedFeature.id })
        } else {
          selectedFeatureIds.current.push(Number(selectedFeature.id))
          postcodeDistrictsRef.current = [
            ...postcodeDistrictsRef.current,
            selectedFeature.properties.name as PostcodeDistrict,
          ]
          onChange(postcodeDistrictsRef.current)
          mapInstance.setFeatureState(
            { source: 'districts', id: selectedFeature.id },
            { selected: true },
          )
        }
      },
    )
    const popup = new maplibregl.Popup({
      closeButton: false,
      closeOnClick: false,
    })
    mapInstance.on('mouseenter', 'districts-fill', function () {
      mapInstance.getCanvas().style.cursor = 'pointer'
    })
    mapInstance.on(
      'mousemove',
      'districts-fill',
      function (
        e: maplibregl.MapMouseEvent & {
          features?: maplibregl.MapGeoJSONFeature[] | undefined
        },
      ) {
        const properties = e.features?.[0].properties
        if (properties) {
          // If the hovered feature is different, update the popup
          if (currentHoveredDistrictRef.current !== properties.name) {
            currentHoveredDistrictRef.current = properties.name as PostcodeDistrict
            popup
              .setLngLat(e.lngLat)
              .setHTML(`<strong>${properties.name}</strong><br>`)
              .addTo(mapInstance)
          } else {
            popup.setLngLat(e.lngLat)
          }
        }
      },
    )
    // Change the cursor back to default when it leaves the feature layer
    mapInstance.on('mouseleave', 'districts-fill', function () {
      mapInstance.getCanvas().style.cursor = ''
      currentHoveredDistrictRef.current = undefined
      popup.remove()
    })

    mapInstance.addControl(new maplibregl.NavigationControl())
    return () => {
      // @ts-expect-error districtMap is a global variable
      window.districtMap = undefined
    }
  }, [setMap, mapRef, onChange, data])

  return (
    <div>
      {loading && <div>loading gojson</div>}
      {error && <div>{error}</div>}
      <div
        ref={mapContainer}
        style={{
          position: 'relative',
          width: '500px',
          height: '500px',
        }}
      />
    </div>
  )
}

function generateNumericId(postcode: string) {
  let hash = 0
  for (let i = 0; i < postcode.length; i++) {
    const char = postcode.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32-bit integer
  }
  return Math.abs(hash) // Ensure the ID is positive
}

export default memo(DistrictMap)
